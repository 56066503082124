import { Injectable } from '@angular/core';
import { AnagHomeFieldConfigurationService, FIELD, FIELD_ANAG_BIRTH_DATE, FIELD_ANAG_BIRTH_NAME, FIELD_ANAG_TAB_PARTY_MANAGEMENT } from '@rgi/anag';
import { ANAG_EXT_FIELD_PLATE, ANAG_EXT_FIELD_POLICY_NUMBER } from '../anag-constants/anag-constants-ext';


@Injectable()
export class AnagHomeFieldConfigurationServiceExt extends AnagHomeFieldConfigurationService{
  constructor() {
    FIELD_ANAG_BIRTH_NAME.visible = 1;
    FIELD_ANAG_BIRTH_DATE.visible = 1;
    FIELD_ANAG_TAB_PARTY_MANAGEMENT.fields.push(ANAG_EXT_FIELD_POLICY_NUMBER);
    FIELD_ANAG_TAB_PARTY_MANAGEMENT.fields.push(ANAG_EXT_FIELD_PLATE);
    super();
  }
}
