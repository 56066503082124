import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    Type, ViewChild, ViewContainerRef
} from '@angular/core';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ModalService} from '@rgi/portal-ng-core';
import {ModalService as AltModalService} from '@rgi/rx/ui';
import {
    MandatoryDataComponent,
    CustomModalService,
    StateService,
    ProposalService,
    PartiesService,
    ParameterService,
    QuotationService,
    EventNotificator,
    Modal,
    RoutingService,
    IvassService,
    PaymentService,
    PassProductsService,
    ElaborateCustomProperties,
    AmendmentService,
    Message,
    Action,
    MicDatePipe,
    RoutableComponent,
    ApiQuotation,
    EntitledQuotation
} from "@rgi/motor-issue-card";

import {ClaimsHistoryModalComponent} from "../../ext/claims/claims-history-modal/claims-history-modal.component";
import {RgiRxUserAuthorizationService} from "@rgi/rx/auth";
import {OperatorService} from '@rgi/portal-ng-core';
import { AuthorizationsSearchService} from "@rgi/authorizations-card";
import { QuotationServiceExt } from '../extension/services/quotation-ext.service';
import { HttpHeaders } from '@angular/common/http';
import { map, mergeMap, tap } from 'rxjs/operators';

@Component({
    templateUrl: './mandatory-data-es-ext.component.html',
    styleUrls: ['./mandatory-data-es-ext.component.scss'],
    providers: [DatePipe]
})
export class MandatoryDataEsExtComponent extends MandatoryDataComponent
    implements OnInit, OnDestroy, RoutableComponent, EventNotificator {
    showClaimsHistoryButton: boolean = false;

    private documentIssuing = false;

    @ViewChild('modalClaimsHistoryOverlay', {
        read: ViewContainerRef,
        static: true
    }) claimsHistoryModalViewContainerRef: ViewContainerRef;

    constructor(
        protected customModalService: CustomModalService,
        protected stateService: StateService,
        protected proposalService: ProposalService,
        protected partiesService: PartiesService,
        protected parametersService: ParameterService,
        protected quotationService: QuotationService,
        translate: TranslateService,
        protected formBuilder: UntypedFormBuilder,
        @Inject('policySummaryModalComponent')
            policySummaryModalComponent: Type<Modal>,
        @Inject('installerModalComponent') installerModalComponent: Type<Modal>,
        protected routingService: RoutingService,
        protected modalService: ModalService,
        protected ivassService: IvassService,
        protected paymentService: PaymentService,
        protected altModalService: AltModalService,
        protected passProductService: PassProductsService,
        protected elaborateCustomProperties: ElaborateCustomProperties,
        @Inject('quotationComparisonModalComponent') quotationComparisonModalComponent: Type<Modal>,
        protected amendmentService: AmendmentService,
        protected userAuthorizationService: RgiRxUserAuthorizationService,
        datePipe: MicDatePipe,
        protected authorizationsSearchService: AuthorizationsSearchService,
		protected operatorervice: OperatorService,
        protected quotationServiceExt: QuotationServiceExt,
        @Inject('showOtpButton') showOtpButton?: boolean  
      
    ) {
        super(customModalService, stateService, proposalService, partiesService, parametersService, quotationService,
        translate, formBuilder, policySummaryModalComponent, installerModalComponent, routingService, modalService,
        ivassService, paymentService, altModalService, passProductService, elaborateCustomProperties, quotationComparisonModalComponent, datePipe, amendmentService, authorizationsSearchService, operatorervice);
    }

    ngOnInit() {
        super.ngOnInit();
        this.showClaimsHistoryButton = this.userAuthorizationService.isAuthorizedFor('claims.history.show');
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    get isAmendment() {
        return this.amendmentService.isAmendment;
    }

    private documentOnIssuing(){
        this.documentIssuing = true;
        super.onIssuing();
    }

    issuePolicy() {
        if (!this.isSubstitution && this.isPolicyNumberEnabled) {
          this.openPolicySummaryModal();
        } else {
          let policyNumber;
          if (this.isSubstitution && this.isPolicyNumberEnabled
            && this.mandatoryDataForm.controls.mandatoryData.get('policyNumber').value
            && this.mandatoryDataForm.controls.mandatoryData.get('policyNumber').value !== '') {
            policyNumber = this.mandatoryDataForm.controls.mandatoryData.get('policyNumber').value;

            this.proposalService.setManualPolicyNumber(policyNumber);
          }

          this.policyMessages.length = 0;
          this.proposalService.savePolicy(this.contractId, policyNumber)
            .subscribe(data => {
              if (data.messages && data.messages.length > 0) {
                data.messages.forEach(msg => {
                  this.policyMessages.push(new Message('POLICY', msg));
                });
                this.enableSaveProposal = false;
              } else {
                this.isPolicyIssued = true;
                this.proposalService.setIssueCompleted();
                this.proposalService.setPolicyInfo(data);
                this.afterIssuePolicy(data);
                if(!this.documentIssuing){
                    this.stateService.nextState(Action.ISSUE_POLICY);
                }
                this.documentIssuing = false;
              }
            }, error => {
              for (const errorMes of error.error.messages) {
                this.policyMessages.push(new Message('POLICY', errorMes));
              }
            });
        }
    }

    openClaimsHistory() {
        const modal = this.customModalService.openModal(this.claimsHistoryModalViewContainerRef, ClaimsHistoryModalComponent);
        modal.instance.contractId = this.proposalService.getApiContract() ? this.proposalService.getApiContract().id : null;
    }

    getQuotationAndVoucherData() {
        return this.quotationService.getQuotation(null).pipe(
            tap((quotation: ApiQuotation) => {
                this.initUnitsList(quotation);
                this.loadPremiumData(quotation);
                this.quotationService.setPreviousAnnualPremium(quotation.premium.productPremium.annual);
            }),
            mergeMap(() => this.loadVoucherData$()),
            mergeMap(() => this.getAvailablePayments$()),
            mergeMap(() => {
                const headers = new HttpHeaders({'RGI_Custom_recalculateClaimHistory': 'true'});
                return this.quotationServiceExt.getQuotation(null, headers, this.proposalService.getContractId());
            }),
            tap((newQuotation: any) => {
                if (newQuotation.premium.extensionData && newQuotation.premium.extensionData.isPremiumChanged) {
                    const premium = newQuotation.premium.productPremium.annual;
                    const entitledQuotation = new EntitledQuotation(premium, null);
                    this.quotationService.setEntitledPremium(entitledQuotation);
                    const quotationModal = this.customModalService.openModal(this.quotationComparisonModalOverlay,
                    this.quotationComparisonModalComponent, this.eventPropagation, () => {
                        if (quotationModal.instance.goEditQuotation) {
                            this.stateService.nextState(Action.EDIT_QUOTATION_BUTTON_PRESSED);
                        } else {
                            this.isQuotationRecalculated = true;
                        }
                        this.formChanged = false;
                    });
                }
            })
        );
    }
}

