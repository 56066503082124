import { Injectable } from "@angular/core";
import { StartService } from "@rgi/motor-issue-card";

@Injectable({
  providedIn: "root",
})
export class IssueCardStartService extends StartService {
  constructor() {
    super();
  }

  /**
   * Get the node with level 8 from the available agencies
   * @param availableAgencies 
   * @returns the node with level 8
   */
  getNode(availableAgencies: any[]) {
    const node = availableAgencies.find((node) => node.level === "8");
    return node ? node : null;
  }

   isAlternativePlateLinkVisible() {
      return false;
    }
}
