import {Inject, Injectable} from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, tap} from 'rxjs/operators';
import {ModalService} from '@rgi/portal-ng-core';
import {UtilitiesService} from '../utilities.service';
import {throwError} from 'rxjs';


const httpError40xToIgnore = [404, 409];


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  eventService: any;

  constructor(private modal: ModalService,
              @Inject('eventService') eventService: any,
              @Inject('authService') protected authService: any) {
    this.eventService = eventService;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
        catchError((err: any, caught) => {
          if (err instanceof HttpErrorResponse) {
            if (httpError40xToIgnore.includes(err.status)){
              return throwError(err);
            }
            let message = `${err.message}`;
            let modalErrors: string[] = [];
            // getting the executionId to help searching the log
            let executionId = req.headers.get('RGI_executionId');
            executionId = !!executionId ? executionId : this.authService.executionId;
            console.log(UtilitiesService.isErrorInterceptorDisable(req));
            if (err.status === 400 && err.error.messages) {
              message = err.error.messages;
            }
            if (!UtilitiesService.isErrorInterceptorDisable(req)) {
              console.log('error interceptor disabled by request');

              // Stop loader
              this.eventService.broadcastEvent('stop-loader');
              modalErrors = ['A server error occurred.', message];
              if (!!executionId) {
                modalErrors.push('Error Code: ' + executionId);
              }
              this.modal.open(modalErrors, 'Error');
            }
            return throwError(err);
          }
        }));
  }

}
