import { AfterContentInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ClaimsHistoryServices } from '../claims-history-services/claims-history-services';
import { ClaimHistory, ClaimHistoryPolicy } from '../models/claim-history';
import { RgiRxDatatableComponent, TableSchema } from '@rgi/rx/ui';
import { CLAIMS_MEDIUM_LEVEL_MTPL_TABLE_SCHEMA, CLAIMS_MEDIUM_LEVEL_MOD_TABLE_SCHEMA } from '../constants/claims-medium-level.tableschema';
import { CLAIMS_HIGH_LEVEL_TABLE_SCHEMA } from '../constants/claims-high-level.tableschema';
import { RgiRxUserAuthorizationService } from '@rgi/rx/auth';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { CLAIMS_BM_LEVEL_TABLE_SCHEMA } from '../constants/claims-bm-level.tableschema';

@Component({
  selector: 'claims-history-ext',
  templateUrl: './claims-history.component.html',
  styleUrls: ['./claims-history.component.scss']
})
export class ClaimsHistoryComponent implements OnInit, OnChanges {
  
  @Input() contractId: any;
  @Input() idMovement: any;
  @Input() isAccordion: boolean;
  @Input() isOpen: boolean;

  @ViewChild('claimsBMTable', { static: false }) claimsBMTable!: RgiRxDatatableComponent;
  @ViewChild('claimsMtplTable', { static: false }) claimsMtplTable!: RgiRxDatatableComponent;
  @ViewChild('claimsModTable', { static: false }) claimsModTable!: RgiRxDatatableComponent;
  @ViewChild('claimsPolicyTable', { static: false }) claimsPolicyTable!: RgiRxDatatableComponent;  

  showLowLevel: boolean = false;
  showMediumLevel: boolean = false;
  showHighLevel: boolean = false;

  labelMTPL: string;
  labelMOD: string;

  CLAIMS_BM_LEVEL_TABLE_SCHEMA: TableSchema = CLAIMS_BM_LEVEL_TABLE_SCHEMA;
  CLAIMS_MEDIUM_LEVEL_MTPL_TABLE_SCHEMA: TableSchema = CLAIMS_MEDIUM_LEVEL_MTPL_TABLE_SCHEMA;
  CLAIMS_MEDIUM_LEVEL_MOD_TABLE_SCHEMA: TableSchema = CLAIMS_MEDIUM_LEVEL_MOD_TABLE_SCHEMA;
  CLAIMS_HIGH_LEVEL_TABLE_SCHEMA: TableSchema = CLAIMS_HIGH_LEVEL_TABLE_SCHEMA;

  claimsHistory: ClaimHistory = null;
  bmLevelTableData: any[] = [];
  claimsTableDataMtpl: any[] = [];
  claimsTableDataMod: any[] = [];
  policiesTableData: any[] = [];
  displayedColumns: string[] = ['descr', 'value'];

  constructor(
    protected claimsHistoryServices: ClaimsHistoryServices,
    protected userAuthorizationService: RgiRxUserAuthorizationService,
    protected translateService: RgiRxTranslationService
  ){}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isOpen && changes.isOpen.currentValue) {
      this.loadHistory();
    }    
  }

  ngOnInit(): void {
    let keys$ = of(['EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._MTPL_', 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._MOD_']);
    keys$.pipe(
      mergeMap(r => {
        return this.translateService.translateAll(...r)
      })
    ).subscribe(next => {
      this.labelMTPL = next[0];
      this.labelMOD = next[1];
    });

    if (this.isOpen === undefined) {
      this.loadHistory();
    }

    this.showLowLevel = this.userAuthorizationService.isAuthorizedFor('claims.history.low');
    this.showMediumLevel = this.userAuthorizationService.isAuthorizedFor('claims.history.medium');
    this.showHighLevel = this.userAuthorizationService.isAuthorizedFor('claims.history.high');
  }

  loadHistory() {
    if (this.contractId) {
      this.claimsHistoryServices.getClaimsHistoryByContractId(this.contractId).subscribe((resp) => {
        if (resp) {
          this.initClaimsHistory(resp);
        }
      }, error => console.error(error));

    } else if (this.idMovement){
      this.claimsHistoryServices.getClaimsHistoryByMovementId(this.idMovement).subscribe((resp) => {
        if (resp) {
          this.initClaimsHistory(resp);
        }
      }, error => console.error(error));

    }
  }

  initClaimsHistory(resp: ClaimHistory) {
    this.claimsHistory = resp;
    if (this.claimsHistory) {

      this.bmLevelTableData.push({
        bmInitial: this.formatValueFromResp(resp.lastLevelBM),
        bmActual: this.formatValueFromResp(resp.currentLevelBM)
      });
      
      this.claimsTableDataMtpl.push({
        description: this.labelMTPL,
        years:  this.formatValueFromResp(resp.currentNumberYearsMtpl),
        claims: this.formatValueFromResp(resp.currentNumberClaims),
        coefficient: this.formatValueFromResp(resp.currentCoefficientMtpl)
      });

      this.claimsTableDataMtpl.push({
        description: this.labelMOD,
        years: this.formatValueFromResp(resp.currentNumberYearsMod),
        claims: this.formatValueFromResp(resp.currentLossRatio) + (this.formatValueFromResp(resp.currentLossRatio)!=='-' ? '%' : ''),
        coefficient: this.formatValueFromResp(resp.currentCoefficientMod)
      });

      /*this.claimsTableDataMod.push({
        description: this.labelMOD,
        years: resp.currentNumberYearsMod,
        claims: resp.currentLossRatio,
        coeff: resp.currentCoefficientMod
      });*/
    
    }

    if (this.claimsHistory && this.claimsHistory.policies) {
      this.fillPoliciesData(this.claimsHistory.policies);
    }

    setTimeout(() => {
      this.claimsBMTable.showPaginator = false;
      this.claimsMtplTable.showPaginator = false;
      if (this.claimsPolicyTable) {
        this.claimsPolicyTable.showPaginator = this.policiesTableData.length > 10;
      }
    }, 1000);    
  }

  fillPoliciesData(policies: Array<ClaimHistoryPolicy>) {
    policies.forEach(policy => {
      if (policy.claims.length) {
        policy.claims.forEach(claim => {
          this.policiesTableData.push(
          {
            policyNumber: policy.policyNumber,
            inceptionDate: policy.inceptionDate,
            cancellationDate: this.formatValueFromResp(policy.cancellationDate),
            licensePlate: policy.licensePlate,
            vehicleType: policy.vehicleType,
            date: claim.date,
            material: claim.material ? 'Y' : 'N',
            bodily: claim.bodily ? 'Y' : 'N'
          });
        });
      } else {
        this.policiesTableData.push(
          {
            policyNumber: policy.policyNumber,
            inceptionDate: policy.inceptionDate,
            cancellationDate: this.formatValueFromResp(policy.cancellationDate),
            licensePlate: policy.licensePlate,
            vehicleType: policy.vehicleType,
            date: '-',
            material: '-',
            bodily: '-'
          });
      }
    });

  }

  formatValueFromResp(value: any) {
    if (value === undefined || value === null || value === -1  || value === '-1') {
      return '-';
    }
    return value;
  }

}
