import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AdditionalData, DscAdditionalDataComponent, DscAdditionalDataService } from '@rgi/digital-claims-angular';
import { ExtProperty, Extension } from '@rgi/group-policy';

@Component({
  selector: 'claims-dsc-additional-data-ext',
  templateUrl: './dsc-additional-data-ext.component.html',
  styleUrls: ['./dsc-additional-data-ext.component.css']
})
export class DscAdditionalDataExtComponent extends DscAdditionalDataComponent implements OnInit {
  causes: any[];
  enumService: any;

  constructor(
    formBuilder: UntypedFormBuilder,
    dataService: DscAdditionalDataService,
    @Inject('enumService') enumService: any
  ) { 
    super(formBuilder, dataService);
    this.enumService = enumService;

    this.additionalDataForm.addControl('causeClaim', new UntypedFormControl(''))
  }

  ngOnInit() {
    this.causes = this.enumService.getEnumList('claims.Causes');

    this.initAdditionalData();
  }

  initAdditionalData() {
    super.initAdditionalData();

    let extension = this.additionalData.extension;
    if (extension && extension.properties && extension.properties.length > 0) {
      let cause = extension.properties.find(property => property.chiave === 'CAUSE');
      this.additionalDataForm.controls.causeClaim.patchValue(cause ? cause.valore : null);
    }

    return extension;
  }

  outputFormChange() {
    this.disableFormField();
    const dataChange: AdditionalData = {...this.additionalData };
    dataChange.antifraudCategoryValue = this.additionalDataForm.controls.antifraudCategoryValue.value;
    dataChange.toBeSettledValue = this.additionalDataForm.controls.toBeSettledValue.value;
    dataChange.lastAuditDateValue = this.additionalDataForm.controls.lastAuditDateValue.value;
    dataChange.authorityDescriptionValue = this.additionalDataForm.controls.authorityDescriptionValue.value;
    dataChange.notIdentifiedWitnessValue = this.additionalDataForm.controls.notIdentifiedWitnessValue.value;
    dataChange.typeOfAuthorityInvolvedValue = this.additionalDataForm.controls.typeOfAuthorityInvolvedValue.value;
    dataChange.catastrophicEventDescriptionValue = this.additionalDataForm.controls.catastrophicEventDescriptionValue.value;
    dataChange.openedClaimDuetoSummonsIssuedValue = this.additionalDataForm.controls.openedClaimDuetoSummonsIssuedValue.value;
    dataChange.externalClaimNumberValue = this.additionalDataForm.controls.externalClaimNumberValue.value;
    dataChange.isValidForm = this.additionalDataForm.valid;
    dataChange.lastAuditDateValue <= this.generalData.occurrenceDate && dataChange.lastAuditDateValue <= this.incidentData.dateReported ? dataChange.lastAuditDateValue : null;

    dataChange.extension = this.getExtension();

    this.outputFormAdditionalData.emit(dataChange);
  }

  getExtension(): Extension {
    let extension = new Extension();

    extension.properties = [];

    if (this.additionalDataForm.controls.causeClaim.value) {
      extension.properties.push(new ExtProperty('CAUSE', this.additionalDataForm.controls.causeClaim.value));
    }

    return extension;
  }
}
