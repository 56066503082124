import { Injectable } from "@angular/core";
import { AnagIntegrationService, AnagResourceService, AnagRouteData, AnagStatelessOpResultService, AnagStorageService, ROUTES_HOME } from "@rgi/anag";
import { RgiRxEventService } from "@rgi/rx";
import { RgiRxUserService } from "@rgi/rx/auth";
import { ActiveRoute, RoutingService } from "@rgi/rx/router";
import { ModalService } from "@rgi/rx/ui";
import { ROUTES_MODAL_HOME_EXT } from "../anag-constants/anag-constants-ext";

@Injectable({
  providedIn: 'root'
})
export class AnagStatelessOpResultServiceExt extends AnagStatelessOpResultService {

  constructor(
    protected routingService: RoutingService,
    protected userService: RgiRxUserService,
    protected anagResources: AnagResourceService,
    protected anagIntegration: AnagIntegrationService,
    protected eventService: RgiRxEventService,
    protected modalService: ModalService,
    protected anagStorage: AnagStorageService, ) {
      super(routingService, userService, anagResources, anagIntegration, eventService, modalService, anagStorage)
  }

  goToHomePage(activeRoute: ActiveRoute) {
    let route = ROUTES_HOME;
    if (activeRoute.getRouteData<AnagRouteData>() && activeRoute.getRouteData<AnagRouteData>().isModalFlow) {
      route = ROUTES_MODAL_HOME_EXT;
      this.anagIntegration.navigate(this.routingService, route, activeRoute.getRouteData<AnagRouteData>(), activeRoute);
    } else {
      this.anagIntegration.backToHome(activeRoute);
    }
  }
}
