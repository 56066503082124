import { AnagHomeFieldConfigurationService, FIELD, FIELD_ANAG_TAB_ACCORPAMENTO, FIELD_ANAG_TAB_PARTY_MANAGEMENT, ROUTES_HOME } from "@rgi/anag";
import { PortalCards, portalControllerFactory } from "@rgi/rx/portal";
import { ActiveRoute } from "@rgi/rx/router";
import { FIELD_ANAG_TAB_PARTY_MANAGEMENT_EXT } from "./anag-constants/anag-constants-ext";
import { ANAG_CARDS } from "@rgi/anag/portal";


function addConfiguredField($scope, anagHomeFieldConfigurationService: AnagHomeFieldConfigurationService, field: FIELD) {
  if ($scope[field.name]) {
    anagHomeFieldConfigurationService.addField($scope.$$id, $scope[field.name]);
  }
}

HomeControllerExt.$inject = ['$scope', '$controller', 'coreResult', 'RgiRxRoutingService', 'RgiRxTranslationService', 'AnagHomeFieldConfigurationService'];

// @ts-ignore
export function HomeControllerExt($scope, $controller, coreResult: ICoreResult, rgiRxRoutingService: RoutingService, rgiRxTranslateService: RgiRxTranslationService, anagHomeFieldConfigurationService: AnagHomeFieldConfigurationService) {
  portalControllerFactory(ROUTES_HOME, 'home').apply(this, arguments); // apply the portalControllerFactory
  $scope.onRouteChange = (_activeRoute: ActiveRoute) => {

  };
  $scope.$on('$destroy', () => {
    anagHomeFieldConfigurationService.clearField($scope.$$id);
  });
 
  FIELD_ANAG_TAB_ACCORPAMENTO.fields.forEach(field => {
    addConfiguredField($scope, anagHomeFieldConfigurationService, field);
  });
  addConfiguredField($scope, anagHomeFieldConfigurationService, FIELD_ANAG_TAB_ACCORPAMENTO);
  FIELD_ANAG_TAB_PARTY_MANAGEMENT_EXT.fields.forEach(field => {
    addConfiguredField($scope, anagHomeFieldConfigurationService, field);
  });
  addConfiguredField($scope, anagHomeFieldConfigurationService, FIELD_ANAG_TAB_PARTY_MANAGEMENT);

  $scope.myCustomData = {};
}

export const ANAG_CARD_EXT: PortalCards = (function () {
  const platformConf = [...ANAG_CARDS];
  platformConf[0].routes.forEach(r => {
      if (r.route === 'home') {
          r.controller = HomeControllerExt;
      }
  });
  return ANAG_CARDS;
})();

