import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxPortalModule} from "@rgi/rx/portal";
import {AUTHORIZATIONS_CARD_EXT} from "./authorizations-card.configurations";


@NgModule({
    imports: [
        CommonModule,
        RgiRxPortalModule.forRoot(AUTHORIZATIONS_CARD_EXT)
    ]
})
export class AuthorizationsModule {
}
