import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { OccurrenceDataModifyComponent, OccurrenceDataModifyState } from '@rgi/digital-claims-angular';
import { AlertService } from '@rgi/digital-claims-common-angular';


@Component({
  selector: 'occurrence-data-modify-ext',
  templateUrl: './occurrence-data-modify-ext.component.html',
  styleUrls: ['./occurrence-data-modify-ext.component.css']
})
export class OccurrenceDataModifyExtComponent extends OccurrenceDataModifyComponent {
  constructor(
    occurrenceService: OccurrenceDataModifyState,
    formBuilder: UntypedFormBuilder,
    alertService: AlertService,
    @Inject('sessionService') session: any,
    @Inject('coreResult') coreResult: any,
    @Inject('eventService') eventService: any,
) {
    super(occurrenceService, formBuilder, alertService, session, coreResult, eventService);
  }

}
