import {DeeplinkTranslationKeys} from './deeplink-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const ITDeeplinkTranslations: DeeplinkTranslationKeys = {
    _DEEP_LINK_: {
        _NOT_FOUND_: 'Non trovato',
        _CLOSE_: 'Chiudi',
        _SYSTEM_MESSAGE_: 'Messaggio di sistema'
    }
}