import { PncPostsalesTranslationsKeys } from './pnc-psales-translations-keys';

/**
* MORE READABILITY ADVICE
* Please maintain an alphabetical order in each section
*/

export const PNC_PSALES_EN: PncPostsalesTranslationsKeys = {
    _PCPSALES_: {
        _BTN_: {
            _BACK_: 'Back',
            _CALCULATE_: 'Calculate',
            _CONFIRM_: 'Confirm',
            _CONTINUE_: 'Continue',
            _DETAIL_: 'Detail',
            _DOCS_: 'Document Management',
            _END_: 'Fine',
            _EXIT_: 'Exit',
            _PRINT_: 'Print',
            _RESET_: 'Reset'
        },
        _LABEL_: {
            _AMOUNT_: 'Amount',
            _ANTIRACKET_: 'Antiracket',
            _APPENDIX_EFFECT_DATE_: 'Appendix Effect Date',
            _APPENDIX_EXPIRY_DATE_: 'Appendix Expiry Date',
            _CANCELLATION_NOTIFICATION_DATE_: 'Cancellation Notification Date',
            _CANCELLATION_REASON_: 'Cancellation reason',
            _CHARGES_: 'Charges',
            _CHARGES_INTERESTS_: 'Charges Interests',
            _COLLECTED_COMMISSIONS_: 'Collected Commissions',
            _EFFECT_DATE_: 'Effective Date',
            _EFFECT_HOUR_: 'Effective Hour',
            _EXCLUSION_NOTIFICATION_DATE_: 'Exclusion Notification Date',
            _EXCLUSION_REASON_: 'Exclusion reason',
            _FEES_: 'Fees',
            _GROSS_: 'Gross',
            _INSURED_RISKS_: 'Insured Risks',
            _ISSUE_DATE_: 'Issue Date',
            _NET_: 'Net',
            _NET_INTERESTS_: 'Net Interests',
            _NOT_INSURED_RISKS_: 'Not Insured Risks',
            _NOTES_: 'Notes',
            _OPERATION_PREMIUM_: 'Operation Premium',
            _PRINT_DOCUMENTS_: 'Print documents',
            _PURCHASED_COMMISSIONS_: 'Purchased Commissions',
            _RATE_: 'Rate',
            _RCA_NET_: 'RCA Net',
            _RIGHTS_: 'Rights',
            _SSN_: 'SSN',
            _TAX_: 'Tax',
            _TAXABLE_: 'Taxable',
            _TAXES_: 'Taxes'
        },
        _MSG_: {
            _ALERT_ACCOUNTING_REVERSAL_: 'Unable to make the reversal, because the movement has a security in status different form AR. Manually proceed with the reversal of the collection of the linked security.\n',
            _CONFIRM_ACCOUNTING_REVERSAL_: 'Attention, in addition to the movement, the Collection will also be canceled. Do you want to continue?',
            _CONFIRM_OPERATION_: 'Do you confirm the operation {{opDescr}} on policy n. {{policyNumber}}?',
            _CONFIRM_REVERSAL_: 'Confirm you want to cancel the movement? Such a cancellation is definitive and the movement cannot be restored.',
            _NO_TECH_ACC_FOUND_: 'No technical accounting found before the variation effective date or collected after',
            _NODE_TO_REQUIRED_: 'The receiving node is required',
            _NOT_ALLOWED_REVERSAL_: 'Reversal movement not allowed.',
            _OPERATION_FOR_POLICY_OK_: 'The operation {{opDescr}} on policy {{policyNumber}} executed successfully',
            _SAME_BRANCHES_: 'The transferring and receiving branches are the same',
            _SAME_NODE_: 'The transferring and receiving nodes are the same',
            _WARNING_ACCOUNTING_REVERSAL_: 'Attention, in addition to the movement, the Collection will also be canceled.',
        },
        _TITLE_: {
            _PREMIUM_REPAYMENT_: 'Premium Repayment',
            _TECH_ACC_LIST_: 'Technical Accounting List',
            _MOVEMENTS_LIST_: 'Movements List'
        },
        _STEPS_: {
            _CANCELLATION_DATA_: 'Cancellation Data',
            _EXCLUSION_DATA_: 'Exclusion Data',
            _TECHNICAL_ACCOUNTING_: 'Technical Accounting',
            _SUMMARY_: 'Summary',
            _VARIATION_DATES_: 'Variation Dates',
            _MOVEMENTS_: 'Movements',
            _SUPPLEMENTARY_TECH_ACC_: 'Supplementary Technical Accounting Premium',
            _POLICY_TRANSFER_DATA_: 'Policy Transfer Data'
        },
        _SESSION_TITLE_: {
            _APPLICATION_EXCLUSION_: 'Application exclusion n. {{policyNumber}}',
            _MOVEMENTS_REVERSAL_: 'Movements reversal n. {{policyNumber}}',
            _POLICY_CANCELLATION_: 'Policy cancellation n. {{policyNumber}}',
            _POLICY_REACTIVATION_: 'Policy reactivation n. {{policyNumber}}',
            _POLICY_TRANSFER_: 'Policy transfer n. {{policyNumber}}',
            _TECHNICAL_ACCOUNTING_APPENDIX_: 'Technical Accounting Appendix policy n. {{policyNumber}}',
            _CLAIMS_INSURANCE_CERTIFICATE_: 'Claims Insurance Certificate policy n. {{policyNumber}}'
        }
    },
    _RGIPNC_: {
        _BTN_: {
            _CANCEL_: 'Cancel',
            _CLOSE_: 'Close',
            _CONFIRM_: 'Confirm'
        },
        _LABEL_: {
            _ACCESSORIES_: 'Accessories',
            _ACQUIRED_CHARGES_: 'Acquired Charges',
            _ACQUIRED_COMMISSION_: 'Acquired Commission',
            _ACTION_: 'Action',
            _ACTIONS_: 'Actions',
            _AMOUNT_: 'Amount',
            _ANNUAL_PREMIUM_: 'Annual premium',
            _ANTIRACKET_: 'Tax Anti Money Laundering',
            _APPENDIX_: 'Appendix',
            _BRANCH_FROM_: 'Transferring Branch',
            _BRANCH_TO_: 'Receiving Branch',
            _CANCELLED_: 'Cancelled',
            _CODE_: 'Code',
            _COLLECTED_CHARGES_: 'Collected Charges',
            _COLLECTED_COMMISSION_NET_: 'Collected Commission (Net)',
            _COMMISSIONS_: 'Commissions',
            _ACCOUNTING_DETAILS_: 'Accounting Details',
            _GROSS_: 'Gross',
            _CURRENCY_: 'Currency',
            _DESCRIPTION_: 'Description',
            _EFFECT_: 'Effetto',
            _EFFECT_DATE_: 'Effective Date',
            _EFFECT_HOUR_: 'Effect Hour',
            _INCEPTION_INSTALLMENT_: 'Instalment at Inception Date',
            _INSTALLMENT_INTEREST_: 'Installment Interest',
            _INSTALLMENT_PREMIUM_: 'Installment premium',
            _INSURANCE_TAX_: 'Insurance Tax',
            _ISSUE_: 'Issue',
            _NET_: 'Net',
            _NEXT_INSTALLMENT_PREMIUM_: 'Next Premium',
            _NODE_: 'Node',
            _NODE_FROM_: 'Transferring Node',
            _NODE_TO_: 'Receiving Node',
            _NUMBER_: 'Number',
            _POLICY_DETAILS_: 'Policy premium detail',
            _TOTAL_PREMIUMS_: 'Total Premium',
            _RATE_: 'Rate',
            _RIGHTS_: 'Rights',
            _SELECT_NODE_: 'Select Node',
            _SIGNED_COMMISSIONS_: 'Commissions at Inception Date',
            _SSN_: 'SSN',
            _STATUS_: 'Status',
            _TAXABLE_: 'Taxable',
            _TAXES_: 'Taxes',
            _TOTAL_: 'Total',
            _TYPE_: 'Type',
            _USER_: 'User',
            _VIEW_: 'View',
            _YES_: 'YES',
            _NO_: 'NO',
        },
        _TITLE_: {
            _NODE_LIST_: 'Node List',
            _SYSTEM_MESSAGE_: 'System message'
        }
    },
    _RE_ISSUE_: {
        _CLOSE_: 'Close',
        _CONFIRM_: 'Confirm',
        _SYSTEM_MESSAGE_: 'System message'
    },
    _VOID_: '',
};
