import {Component} from '@angular/core';
import {AnagHomeComponent, ANAG_TABS} from '@rgi/anag';
import { UntypedFormControl } from '@angular/forms';
import { ANAG_EXT_FIELD_POLICY_NUMBER, ANAG_EXT_FIELD_PLATE } from '../../anag-constants/anag-constants-ext';
import {take, tap } from 'rxjs/operators';
import { Agency } from '@rgi/portal-ng-common';


@Component({
    selector: 'rgi-anag-home',
    templateUrl: './anag-home.component-ext.html',
    host: {
        class: 'rgi-anag-style'
    }
})
export class AnagHomeComponentExt extends AnagHomeComponent {
    ANAG_EXT_FIELD_POLICY_NUMBER = ANAG_EXT_FIELD_POLICY_NUMBER;
    ANAG_EXT_FIELD_PLATE = ANAG_EXT_FIELD_PLATE;

    maxNode = 10;
    node: any;

    ngOnInit() {
        this.searchPartyForm.addControl('policyNumber', new UntypedFormControl(''));
        this.searchPartyForm.addControl('plate', new UntypedFormControl(''));    
        super.ngOnInit();
        this.searchPartyForm.get('node').valueChanges.pipe(take(1)).subscribe(value => {
            if (value && this.state && this.state.nodes) {
                this.overrideNode(value);
            }
        });

    }

    overrideNode(nodeCode: string) {
        const currentNode = this.state.nodes.find(el => el.code === nodeCode);
        if (currentNode) {
            // node saved always to manage both select and drop-container
            this.node = currentNode;
            if (this.state.nodes.length >= this.maxNode) {
                this.searchPartyForm.get('node').setValue(currentNode);
            }
        }
    }

    findPartyExt() {
        if (this.tabs !== ANAG_TABS.PARTY_MERGER) {
            //const selectedNode: Agency = this.searchPartyForm.get('node').value;
            if (this.state.nodes.length >= this.maxNode && this.node) {
                this.searchPartyForm.get('node').setValue(this.node.code);
            }
        }
        this.findParty();
        if (this.state.nodes.length >= this.maxNode && this.node) {
            this.searchPartyForm.get('node').setValue(this.node);
        }
    }

    dragChangeHandlerNode(event: any) {
        this.node = event.changed;
    }

}
