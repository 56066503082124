import { NgModule } from "@angular/core";
import { DeepLinkService } from "./services/deeplink.services";
import { ActiveRoute } from "@rgi/rx/router";
import { DeeplinkModalComponent } from './deeplink-modal/deeplink-modal.component';
import { RgiRxPanelModule } from "@rgi/rx/ui";
import { CommonModule } from "@angular/common";
import { RgiRxI18nModule } from "@rgi/rx/i18n";
import { EXT_DEEPLINK_TRANSLATIONS } from "./i18n/translations";

@NgModule({
    providers: [
        DeepLinkService,
        ActiveRoute
    ],
    declarations: [
      DeeplinkModalComponent
    ],
    imports: [
      CommonModule,
      RgiRxPanelModule,
      RgiRxI18nModule.forRoot(EXT_DEEPLINK_TRANSLATIONS),
    ]
})
export class DeepLinkModule {
    
}