export class AnagPartyKeyExt {
    public name: string;
    public surname: string;
    public denomination: string;

    constructor(name: string, surname: string, denomination: string) {
        this.name = name;
        this.surname = surname;
        this.denomination = denomination;
    }
}
