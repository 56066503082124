import {Injectable, Inject} from '@angular/core';
import { Observable, of} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PV_TOKEN } from '@rgi/life-postsales-card';
import { ClaimHistory } from '../models/claim-history';

@Injectable({
  providedIn: 'root'
})
export class ClaimsHistoryServices {
  baseApiUrl: string;
 
  constructor(
              private httpClient: HttpClient,
              @Inject(PV_TOKEN.ENV) protected environment: any,
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }

  /**
   * Retrieves claims history for contracId in cache 
   * @param contractId - The ID contract.
   * @returns An Observable that contains claims history.
   */
  getClaimsHistoryByContractId(contractId: any): Observable<ClaimHistory>  {
    if (!contractId) {
      console.error('Contract id null');
      return of(null);
    }
   
    const url = `${this.baseApiUrl}/iiab-sp/infoclaims/${contractId}`;
    return this.httpClient.get<ClaimHistory>(url);
  }

  /**
   * Retrieves claims history for contracId in cache 
   * @param movementId - The ID contract.
   * @returns An Observable that contains claims history.
   */
  getClaimsHistoryByMovementId(movementId: any): Observable<ClaimHistory>  {
    if (!movementId) {
      console.error('movementId  null');
      return of(null);
    }
   
    const url = `${this.baseApiUrl}/iiab-sp/infoclaims/consultation/${movementId}`;
    return this.httpClient.get<ClaimHistory>(url);
  }

  /**
   * Retrieves if claims history is changed for contracId in cache 
   * @param contractId - The ID contract.
   * @returns An Observable that contains output: changeHistory: boolean.
   */
  getClaimsHistoryChanged(contractId: any): Observable<any>  {
    if (!contractId) {
      console.error('Contract id null');
      return of(null);
    }
   
    const url = `${this.baseApiUrl}/iiab-sp/changeHistoryClaims/${contractId}`;
    return this.httpClient.get<any>(url);
  }

}
