import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RestApiConf, PNC_POSTSALES_OPERATIONS_TYPES, PncPostSalesCreateDraftResponse, pncPostsalesOperationDescMap, PolicyOperationsResponse, PolicyOperationPayload, OperatorLight } from '@rgi/pnc-postsales';
import { CancellationFullProperties, CancelProperties } from '@rgi/pnc-postsales/lib/resources/model/api/cancellation-flow';
import { UserService } from '@rgi/rx/auth';
import { RgiRxHttpClientWrapper } from '@rgi/rx/http';
import { Observable } from 'rxjs';
import { Documents } from '../model/api/document';


@Injectable({
    providedIn: 'root'
})
export class PncPsalesClaimsInsuranceApiService {
    protected hostPath: string;

    constructor(
        @Inject('environment') protected apiConf: RestApiConf,
        public httpClient: RgiRxHttpClientWrapper,
        protected userService: UserService
    ) {
        this.hostPath = this.apiConf.api.portal.host + this.apiConf.api.portal.path;
    }


    createDraft$(policyNumber: string, operationType: PNC_POSTSALES_OPERATIONS_TYPES | string, cancellationCode: string, existingDraftId: string): Observable<PncPostSalesCreateDraftResponse> {
        let params = new HttpParams();
        const opType = pncPostsalesOperationDescMap.get(operationType);
        if (!!existingDraftId) {
            params = params.append('contractId', existingDraftId);
        }
        return this.httpClient.post<PncPostSalesCreateDraftResponse>(`${this.hostPath}/v2/portfolio-postsales/pc/policies/${policyNumber}/operations/${opType}/${cancellationCode}/drafts`, {}, { params });
    }

    getDraftData$(policyNumber: string, operationType: PNC_POSTSALES_OPERATIONS_TYPES | string, operationCode: string, draftId: string): Observable<CancellationFullProperties> {
        const opType = pncPostsalesOperationDescMap.get(operationType);
        return this.httpClient.get<CancellationFullProperties>(`${this.hostPath}/v2/portfolio-postsales/pc/policies/${policyNumber}/operations/${opType}/${operationCode}/drafts/${draftId}`);
    }

    updateDraftData$(policyNumber: string, operationType: PNC_POSTSALES_OPERATIONS_TYPES | string, operationCode: string, draftId: string, payload: CancelProperties): Observable<any> {
        const opType = pncPostsalesOperationDescMap.get(operationType);
        return this.httpClient.put<any>(`${this.hostPath}/v2/portfolio-postsales/pc/policies/${policyNumber}/operations/${opType}/${operationCode}/drafts/${draftId}`, payload);
    }

    finalizeOperation$(policyNumber: string, operationType: PNC_POSTSALES_OPERATIONS_TYPES | string, operationCode: string, draftId: string): Observable<any> {
        const opType = pncPostsalesOperationDescMap.get(operationType);
        return this.httpClient.post<any>(`${this.hostPath}/v2/portfolio-postsales/pc/policies/${policyNumber}/operations/${opType}/${operationCode}/drafts/${draftId}/execution`, {});
    }

    getPolicyOperations$(contractId: string): Observable<PolicyOperationsResponse> {
        const payload: PolicyOperationPayload = {
            operationsInput: {
                userCode: this.userService.getUser<OperatorLight>().username,
                productionNodeCode: this.userService.getUser<OperatorLight>().salePoint.code,
                authenticationNodeCode: this.userService.getUser<OperatorLight>().salePoint.code,
                objectId:
                {
                    description: '1',
                    identification: contractId
                }
            }
        };
        return this.httpClient.post<PolicyOperationsResponse>(`${this.hostPath}/ptfdanni/policyoperations`, payload);
    }

    getDocuments$(policyNumber: string, movementCode: string): Observable<Documents> {
        return this.httpClient.get<Documents>(`${this.hostPath}/v2/portfolio/pc/contract/${policyNumber}/documents?movementCode=${movementCode}`);
    }

    downloadDocuments$(policyNumber: string, documentCode: string): Observable<Blob> {
        return this.httpClient.get(`${this.hostPath}/v2/portfolio/pc/contract/${policyNumber}/document/${documentCode}/download`, { responseType: 'blob' });
    }
}
