import { FactoryProvider } from "@angular/core";

export function ptfallFilterServiceFactory(i: any) {
    return i.get('ptfallFilterService');
  }
  
  const ptfallFilterServiceProvider = {
    provide: 'ptfallFilterService',
    useFactory: ptfallFilterServiceFactory,
    deps: ['$injector']
  };
  
  export function ptfAllFinderFormServiceFactory(i: any) {
    return i.get('ptfAllFinderFormService');
  }
  
  const ptfAllFinderFormServiceProvider = {
    provide: 'ptfAllFinderFormService',
    useFactory: ptfAllFinderFormServiceFactory,
    deps: ['$injector']
  };
  
  export function PtfAllFinderResultServiceFactory(i: any) {
    return i.get('ptfAllFinderResultService');
  }
  
  const PtfAllFinderResultServiceProvider = {
    provide: 'ptfAllFinderResultService',
    useFactory: PtfAllFinderResultServiceFactory,
    deps: ['$injector']
  };
  
  export function PassqqProductsFactory(i: any) {
    return i.get('PassqqProducts');
  }
  
  const passqqProductsProvider = {
    provide: 'PassqqProducts',
    useFactory: PassqqProductsFactory,
    deps: ['$injector']
  };
  
  export function passqqFinderFilterServiceFactory(i: any) {
    return i.get('passqqFinderFilterService');
  }
  
  const passqqFinderFilterServiceProvider = {
    provide: 'passqqFinderFilterService',
    useFactory: passqqFinderFilterServiceFactory,
    deps: ['$injector']
  };
  
  export function passqqQuoteDetailFactory(i: any) {
    return i.get('PassqqQuoteDetail');
  }
  
  const passqqQuoteDetailProvider = {
    provide: 'PassqqQuoteDetail',
    useFactory: passqqQuoteDetailFactory,
    deps: ['$injector']
  };

  export function accountingSecuritiesJumpServicesFactory(i: any) {
    return i.get('accountingSecuritiesJumpServices');
  }
  
  const accountingSecuritiesJumpServicesProvider = {
    provide: 'accountingSecuritiesJumpServices',
    useFactory: accountingSecuritiesJumpServicesFactory,
    deps: ['$injector']
  };

  export function cardPassServiceFactory(i: any) {
    return i.get('cardPassService');
  }
  
  const cardPassServiceProvider = {
    provide: 'cardPassService',
    useFactory: cardPassServiceFactory,
    deps: ['$injector']
  };  

  export function claimsFinderFilterServiceFactory(i: any) {
    return i.get('claimsFinderFilterService');
  }
  
  const claimsFinderFilterServiceProvider = {
    provide: 'claimsFinderFilterService',
    useFactory: claimsFinderFilterServiceFactory,
    deps: ['$injector']
  };

  export function getSystemPropertyFactory(i: any) {
    return i.get('getSystemProperty');
  }
  
  const getSystemPropertyProvider = {
    provide: 'getSystemProperty',
    useFactory: getSystemPropertyFactory,
    deps: ['$injector']
  };

  export function claimsServiceFactory(i: any) {
    return i.get('claimsService');
  }
  
  const claimsServiceProvider = {
    provide: 'claimsService',
    useFactory: claimsServiceFactory,
    deps: ['$injector']
  };  
  
  export const PORTAL_AJS_DEEPLINK_PROVIDERS: FactoryProvider[] = [
    ptfallFilterServiceProvider,
    ptfAllFinderFormServiceProvider,
    PtfAllFinderResultServiceProvider,
    passqqProductsProvider,
    passqqFinderFilterServiceProvider,
    passqqQuoteDetailProvider,
    accountingSecuritiesJumpServicesProvider,
    cardPassServiceProvider,
    claimsFinderFilterServiceProvider,
    getSystemPropertyProvider,
    claimsServiceProvider
]