import { downgradeComponent } from '@angular/upgrade/static';

// Claims
import { registerTimelineCard } from './ajs-cards/claims-timeline';
import { registerChatCard } from './ajs-cards/claims-chat';
import { registerActivityListCard } from './ajs-cards/claims-activity-list';
import { registerClaimOpeningCard } from './ajs-cards/claims-claim-opening';

import { ModalComponent } from '@rgi/portal-ng-core';
import { CardService } from '@rgi/digital-claims-common-angular';
import { AccountingTransactionsComponent, ExternalPortfolioExtrainfoComponent, FeeDataModalComponent, ReassignTaskComponent, CompensationListComponent } from '@rgi/digital-claims-angular';

// ANGULARJS CONFIGURATION
declare const angular: angular.IAngularStatic;

export function AjsConfiguration(cardService: CardService) {

  // Events
  angular.module('core').config(['eventServiceProvider', (evProvider) => {
    evProvider.registerEvent('gettextLanguageChanged', { type: 'i18n' });
  }]);

  // Cards
  // Claims
  registerTimelineCard(angular);
  registerChatCard(cardService);
  registerActivityListCard(cardService);
  registerClaimOpeningCard(cardService);

  // Other components
  angular.module('app').directive(
      'pncModal', downgradeComponent({ component: ModalComponent })
  );

  angular.module('reassign-task', []).directive('reassignTask',
      downgradeComponent({ component: ReassignTaskComponent }) as angular.IDirectiveFactory);
  angular.module('accounting-transactions', []).directive('accountingTransactions',
      downgradeComponent({ component: AccountingTransactionsComponent }) as angular.IDirectiveFactory);
  angular.module('external-portfolio-extrainfo', []).directive('externalPortfolioExtrainfo',
      downgradeComponent({ component: ExternalPortfolioExtrainfoComponent }) as angular.IDirectiveFactory);
  angular.module('modify-ndc-legal', []).directive('modifyNdcLegal',
      downgradeComponent({ component: FeeDataModalComponent }) as angular.IDirectiveFactory);
  angular.module('compensation-list', []).directive('compensationList',
      downgradeComponent({component: CompensationListComponent}) as angular.IDirectiveFactory);
}