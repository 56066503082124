import {PortalCards,} from '@rgi/rx/portal';
import {AuthorizationFilter} from "@rgi/authorizations-card";
import {AUTHORIZATIONS_CARD} from "@rgi/authorizations-card/portal";

proxyController.$inject = ['$scope', '$controller', 'coreResult', 'RgiRxRoutingService', 'RgiRxPortalCardService', 'RgiRxTranslationService',
    'RgiRxTemplateInterpolationService', 'containerCacheService', 'authorizationsSearchService', 'authService', 'sessionService', 'DeepLinkLegacyService'];

function proxyController(scope, controller, coreResult, RgiRxRoutingService, RgiRxPortalCardService, RgiRxTranslationService,
                         RgiRxTemplateInterpolationService, containerCacheService, authorizationsSearchService, authService, sessionService, DeepLinkLegacyService) {

    let data = containerCacheService.get(scope.card.idSession);

    const filter: AuthorizationFilter = new AuthorizationFilter();
    filter.userLogin = authService.getOperator().username;
    filter.nodeCode = authService.getOperator().salePoint.objectId;
    filter.subtree = true;
    filter.idSubject = data.data.objectId;
    scope.onSelect = (auth) => {
        if (auth.authOrigin=='IIAB') {
            authorizationsSearchService.getAuthorizationDetailById(auth.authId).subscribe(data => {
                const id = sessionService.open('authorizationsCard', 'authorizations/detail')
                coreResult.setResult(id, 'authorizations/detail', data);
            });
        } else {
            DeepLinkLegacyService.gotoAuthorization(auth.authCode, auth.authId);
        }
        
    };

    const subscription = authorizationsSearchService.getAuthorizationsListFromSubcard(filter).subscribe(authorizations => {
        scope.authorizations = authorizations;
    });

    scope.$on('$destroy', () => {
        subscription.unsubscribe()
    });
}

export const AUTHORIZATIONS_CARD_EXT: PortalCards = (function () {
    const platformConf = [...AUTHORIZATIONS_CARD];
    platformConf[0].routes.forEach(r => {
        if (r.route === 'proxy') {
            r.controller = proxyController;
        }
    });
    return AUTHORIZATIONS_CARD;
})();
