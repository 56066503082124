import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AmendmentService, CustomModalService, ParameterService, PartiesService,
    PolicySummaryComponent, 
    ProposalService, QuotationService, StateService, DocumentsModalComponent } from '@rgi/motor-issue-card';
import {ProposalOtpService, OtpService} from "@rgi/iiab-portal-ext";
import {Subscription} from "rxjs";
import {ModalService} from "@rgi/rx/ui";



@Component({
    selector: 'mic-policy-summary-ext',
    templateUrl: './policy-summary.component.html',
    styleUrls: ['./policy-summary.component.scss']
  })

export class PolicySummaryExtComponent extends PolicySummaryComponent {

    digitalSigned: boolean = false;
    policySubscriber: any;
    documentList: any[] = [];
    otpSectionMessage: string = '';
    showOtpDocuments = true;
    private $subscriptionsList: Subscription[] = [];
    anagSubject: any;

    constructor(
        protected customModalService: CustomModalService,
        protected stateService: StateService,
        protected proposalService: ProposalService,
        protected partiesService: PartiesService,
        protected parametersService: ParameterService,
        protected quotationService: QuotationService,
        protected translate: TranslateService,
        protected amendmentService: AmendmentService,
        private proposalOtpService: ProposalOtpService,
        private otpService: OtpService,
        protected modalService: ModalService,
        @Inject('showOtpButton') showOtpButton?: boolean
        ) {
        super(customModalService, stateService, proposalService, partiesService,
            parametersService, quotationService, translate, amendmentService, modalService, showOtpButton);
    }

    ngOnInit() {
        super.ngOnInit();
        this.$subscriptionsList.push(this.otpService.callOtpAbilitation().subscribe(() => this.showOtpDocuments = this.otpService.otpAbilitation));
        if(!this.isAmendment){
            this.getParty();
        }else{
            this.digitalSigned = true;
        }
        this.getDocumentList();
    }

    getParty() {
        this.proposalOtpService.getParties(this.proposalService.getContractId()).subscribe((res: any) => {
            this.policySubscriber = res.subscriber;
            this.getAnagParty();
        });
    }

    getAnagParty() {
        this.proposalOtpService.getSubjectData(this.policySubscriber).subscribe(resAnagSub => {
            this.anagSubject = resAnagSub.subject;
        });
    }

    digitalSignVisible(){
        if (this.showOtpDocuments && this.documentList && this.documentList.length > 0
            && this.policySubscriber && this.policySubscriber.mobilePhone
            && this.policySubscriber.mobilePhone.length > 0 && this.policySubscriber.mobilePhone[0].number
            && this.policySubscriber.emails && this.policySubscriber.emails.length > 0
            && this.policySubscriber.privacyConsensus && this.policySubscriber.privacyConsensus.length > 0) {
            const existsPhoneNumber = this.policySubscriber.mobilePhone[0].number !== '';
            const privacyOTYes = this.policySubscriber.privacyConsensus.filter(p => p.privacy.code === '_FROTP' && p.privacyConsensusValue.code === '1');
            const existsPrivacyConsensus = privacyOTYes.length > 0;
            const existsDocument = this.anagSubject && this.anagSubject.document && this.anagSubject.document.documentType
                && this.anagSubject.document.documentNumber && this.anagSubject.document.locationsRelease
                && this.anagSubject.document.releaseDate && this.anagSubject.document.expirationDate;
            return existsPhoneNumber && existsPrivacyConsensus && !!existsDocument;
        }
        return false;
    }

    openOTP(){
        const policyInfo = this.proposalService.getPolicyInfo();
        const movementId = policyInfo ? policyInfo.movementNumber : undefined;

        this.otpService.signDocuments(this.getPolicyId(policyInfo.contractNumber) ,movementId, this.policySubscriber.partyId).subscribe(res => {
            if (res) {
                this.otpSectionMessage = ' Signing process initiated.';
                this.digitalSigned = true;
                this.isEndButtonEnabled = true;
            }
        });

    }

    getPolicyId(contractNumber: any){
        return contractNumber.contractId;
    }

    getDocumentList(){
        this.proposalService.getPolicyDocuments().subscribe(
            (data) => {
                this.documentList = data.documents;
            }
        );
    }

    getMessage(){
        if (this.digitalSigned){
            return this.otpSectionMessage;
        } else {
            return this.savePolicyMessage;
        }
    }

    ngOnDestroy(): void {
        this.$subscriptionsList.forEach(subscription => subscription.unsubscribe());
    }

    get isSubstitution() {
        return this.proposalService.isSubstitution;
    }

    get isAmendment() {
        return this.amendmentService.isAmendment;
    }

	openDownloadModal() {
    this.isEndButtonEnabled = true;
    this.isPrintButtonClicked = true;
    this.proposalService.getPolicyDocuments().subscribe(
      (data) => {
        const comp = this.customModalService.openModal(this.modalOverlayRef, DocumentsModalComponent, this.eventPropagation);
        comp.instance.documents = data.documents;
        comp.instance.title = 'Policy Documents';

      }
    );
   }

}
