import {Component, Inject, Optional} from '@angular/core';
import {RgiRxEventService} from '@rgi/rx';
import {DIALOG_DATA} from '@rgi/rx/ui';
import { RoutingService} from '@rgi/rx/router';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import { AnagRouteData, AnagModalComponent} from '@rgi/anag';
import { ROUTES_MODAL_HOME_EXT } from '../../anag-constants/anag-constants-ext';

@Component({
  selector: 'rgi-anag-modal',
  templateUrl: './anag-modal.component-ext.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagModalComponentExt extends AnagModalComponent {

  constructor(
    protected eventService: RgiRxEventService,
    protected routingService: RoutingService,
    protected translateService: RgiRxTranslationService,
    @Optional() @Inject(DIALOG_DATA) data: AnagRouteData
  ) {
    super(eventService, routingService, translateService, data);
  }

  ngOnInit() {
    super.ngOnInit();
    this.currentRoute = ROUTES_MODAL_HOME_EXT;
  }

  protected setTitle() {
    super.setTitle();
    if (this.currentRoute === ROUTES_MODAL_HOME_EXT) {
      this.translateService.translate('_ANAG_._LABEL_._PARTY_MANAGEMENT_').subscribe(title => this.modalTitle = title).unsubscribe();
    }
  }
}
