import {AnagExtensionSubject, AnagStateHome, AnagStateManagerHome} from "@rgi/anag";

export class AnagStateManagerHomeExt extends AnagStateManagerHome {


    adaptFormToState(state: AnagStateHome, form: any) {
        super.adaptFormToState(state, form);
        if (this.existsExtraFilter(form)) {
            state.extensionSubject = new AnagExtensionSubject();
            state.extensionSubject.properties = [];
            this.addExtensionProperty(state.extensionSubject.properties, 'FILTER_PLATE', form.plate);
            this.addExtensionProperty(state.extensionSubject.properties, 'FILTER_POLICY_NUMBER', form.policyNumber);
        }
    }

    private existsExtraFilter(form: any) {
        return form.plate || form.policyNumber;
    }

    private addExtensionProperty(extensions: Array<{
        chiave: string;
        valore: string;
    }>, key: string, value: string) {
        if (value) {
            extensions.push({chiave: key, valore: value});
        }
    }
}
