import { registerCommunnicationController } from './claims';
import { downgradeComponent } from '@angular/upgrade/static';
import { Type } from '@angular/core';

let idCardRegister = 0;

function getIdCard() {
    return idCardRegister++;
}

export function registerCard(
    moduleName: string,
    title: string,
    cardComponent: Type<any>,
    angular: angular.IAngularStatic
) {
    const cardName = camelizeKebab('claims-' + moduleName);
    const claimsModule = angular.module('claims-card-' + moduleName, []);
    const controller = registerCommunnicationController(angular, moduleName, getDirective);
    const cardType = 'main';

    const card = configureModuleForCard(claimsModule, title, cardType, cardName);

    configureParallelRoutingProvider(claimsModule, cardName, controller, title);

    function getDirective() {
        return { name: 'claims-' + moduleName + '-' + getIdCard() };
    }

    const dir = claimsModule.directive(
        cardName, downgradeComponent({ component: cardComponent })
    );

    angular.module('claims-cards').requires.push('claims-card-' + moduleName);
}

export function registerSubCard(
    moduleName: string,
    title: string,
    cardComponent: Type<any>,
    parentCardName: string,
    angular: angular.IAngularStatic
) {
    const cardName = camelizeKebab('claims-' + moduleName);
    const claimsModule = angular.module('claims-card-' + moduleName, []);
    const controller = registerCommunnicationController(angular, moduleName, getDirective);
    const cardType = 'subCard';

    configureModuleForSubCard(claimsModule, title, cardType, cardName, parentCardName);

    configureParallelRoutingProvider(claimsModule, cardName, controller, title);

    function getDirective() {
        return { name: 'claims-' + moduleName + '-' + getIdCard() };
    }

    claimsModule.directive(
        cardName, downgradeComponent({ component: cardComponent })
    );

    cardComponent.name;

    angular.module('claims-cards').requires.push('claims-card-' + moduleName);
}

export function camelizeKebab(str: string) {
    const arr = str.split('-');
    const capital = arr.map((item, index) => index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item);
    const capitalString = capital.join("");

    return capitalString;
}

//TODO: once everything's moved, remove the export
export function configureModuleForCard(claimsModule: any, title: string, type: string, cardName: string) {
    const card = getCardObject(cardName, title, type);

    claimsModule.config([
        'cardManagementServiceProvider',
        function (cardManagementServiceProvider) {
            cardManagementServiceProvider.register(card);
        }
    ]);

    return card;
}

function configureModuleForSubCard(
    claimsModule: any,
    title: string,
    type: string,
    cardName: string,
    parentCardName: string) {
    const card = getCardObject(cardName, title, type);
    claimsModule.config([
        'cardManagementServiceProvider',
        function (cardManagementServiceProvider) {
            cardManagementServiceProvider.register(card);
            registerAsSubcard(cardManagementServiceProvider, parentCardName, cardName, { potentialClaim: true, claim: true });
        }

    ]);
    return card;
}

//TODO: once everything's moved, remove the export
export function configureParallelRoutingProvider(claimsModule: any, cardName: string, controller: any, title: string) {
    claimsModule.config(['parallelRoutingProvider', function (pRouter) {
        pRouter.when(cardName, 'home', {
            controller: controller,
            template: 'assets/claims-communication.tpl.html',
            label: 'Home - ' + title,
            type: 'home'
        });
    }]);

}

function registerAsSubcard(
    cardManagementServiceProvider: any,
    mainCardName: string,
    subCardName: string,
    type: { potentialClaim: boolean, claim: boolean }) {

    const mainCard = cardManagementServiceProvider.$get().getAvailableCardByName(mainCardName);
    const claimsSubcard = mainCard.subCards[0].cards;
    const potentialClaimSubcard = mainCard.subCards[1].cards;

    if (type.potentialClaim) {
        potentialClaimSubcard.push({ name: subCardName, route: 'home' });
    }

    if (type.claim) {
        claimsSubcard.push({ name: subCardName, route: 'home' });
    }
}

function getCardObject(cardName: string, title: string, type: string) {
    return {
        category: 'Claims',
        name: cardName,
        navigationInSession: false,
        subCards: [],
        subCardsActive: [],
        title: title,
        tpl: '',
        type: type,
        customize: {
            enabled: false,
            hideHeader: false,
            hideContainerBody: false
        },
    };
}
