import {AfterViewInit, Component, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {
    AnagApiParty,
    AnagConfigService,
    AnagEntityIta,
    AnagStatelessService,
    AnagStateManagerPartyEditor,
    AnagStorageService
} from '@rgi/anag';
import {PushMessageHandlerService} from "@rgi/rx";
import {RgiRxTranslationService} from "@rgi/rx/i18n";
import {RgiRxUserService} from "@rgi/rx/auth";
import {AnagPrivacyComponentExt} from '@rgi/iiab-portal-ext';

@Component({
    selector: 'rgi-anag-privacy',
    templateUrl: './anag-privacy.component-es-ext.html',
    host: {
        class: 'rgi-anag-style'
    }
})
export class AnagPrivacyComponentEsExt extends AnagPrivacyComponentExt implements OnInit, OnModalClose, AfterViewInit {

    constructor(
        public stateMgr: AnagStateManagerPartyEditor,
        public anagStorage: AnagStorageService,
        public anagStatelessService: AnagStatelessService,
        public pushMessageHandler: PushMessageHandlerService,
        protected translateService: RgiRxTranslationService,
        public configService: AnagConfigService,
        protected userService: RgiRxUserService,
        @Optional() @Inject(DIALOG_DATA) party: AnagApiParty
    ) {
        super(stateMgr, anagStorage, anagStatelessService, pushMessageHandler, translateService, configService, userService, party);
    }

    protected readonly EXADVCONS_CODE = '000002';
    protected readonly ROBINSONLIST_FX = 'ROLEDT';
    protected readonly EXADVCONS_FX = 'EXAD2W';

    ngAfterViewInit(): void {

        super.ngAfterViewInit();
        this.setDefaultValueForPrivacies();
    }

    private setDefaultValueForPrivacies() {
        const advConsValue = this.privacyForm.controls[Number(this.EXADVCONS_CODE)].value;
        if (!advConsValue || advConsValue === '3') {
            this.privacyForm.controls[Number(this.EXADVCONS_CODE)].setValue("2");
        }
    }

    questionOptionsFiltered(question: AnagEntityIta) {
        if (Number(question.codice) === Number(this.EXADVCONS_CODE)) {
            return super.questionOptions.filter(p => p.codice !== '3')
        }
        return super.questionOptions;
    }
}
