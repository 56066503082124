import {
    API_PREFIX_PORTFOLIO, PNC_POSTSALES_OPERATIONS_TYPES, PncDomainService,
    PncPsalesSummaryStateManagerVariations, PostSalesOperation, RGI_PNC_POSTSALES_STEPS
} from "@rgi/pnc-postsales";
import { PNC_POSTSALES_OPERATIONS_CODE_EXT } from "../enum/pnc-postsales-operations-types-ext";
import { PncPsalesRequiredDataClaimsInsuranceCertificateStateManager } from "../ops-managers/variations/required-data/required-data-claims-insurance-state-manager-dates";
import { PncPsalesConfirmationStateManagerClaimsInsurance } from "../ops-managers/variations/confirmation/confirmation-state-manager-claims-insurance";
import { PncPsalesClaimsInsuranceApiService } from "../resources/http/pnc-psales-claims-insurance-api.service";

export const PNC_PSALES_CLACRT: PostSalesOperation = {
    type: PNC_POSTSALES_OPERATIONS_TYPES.ASSETS_DATA_AMENDMENTS,
    operationCode: PNC_POSTSALES_OPERATIONS_CODE_EXT.CLAIMS_INSURANCE_CERTIFICATE,
    sessionTitle: '_PCPSALES_._SESSION_TITLE_._CLAIMS_INSURANCE_CERTIFICATE_',
    steps: [
        {
            step: RGI_PNC_POSTSALES_STEPS.REQUIRED_DATA,
            manager: PncPsalesRequiredDataClaimsInsuranceCertificateStateManager,
            deps: [PncDomainService],
            stepLabel: '_PCPSALES_._STEPS_._VARIATION_DATES_',
            context: { apiPrefix: API_PREFIX_PORTFOLIO }
        },
        {
            step: RGI_PNC_POSTSALES_STEPS.SUMMARY,
            manager: PncPsalesSummaryStateManagerVariations,
            deps: [PncDomainService],
            stepLabel: '_PCPSALES_._STEPS_._SUMMARY_',
            context: { apiPrefix: API_PREFIX_PORTFOLIO }
        },
        {
            step: RGI_PNC_POSTSALES_STEPS.CONFIRMATION,
            manager: PncPsalesConfirmationStateManagerClaimsInsurance,
            deps: [PncPsalesClaimsInsuranceApiService],
            stepLabel: ''
        }
    ]
};
