import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AnagApiParty, AnagPartyKeyService, AnagResourceService } from '@rgi/anag';
import { UserService } from '@rgi/rx/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnagPartyKeyExt } from "../anag-domain/anag-domain";
import { PushMessageHandlerService, RgiRxPushMessage } from "@rgi/rx";
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { AnagApiCreateSubject, AnagApiSaveSubjectResponse } from '@rgi/anag';
import {ModalService} from '@rgi/rx/ui';
import { AnagDialogComponent } from '@rgi/anag';


@Injectable()
export class AnagResourceServiceExt extends AnagResourceService {

  private readonly excludedProfessionTypeCodes = [
    '2048',
    '4096',
    '8192',
    '16384'
  ];

  constructor(
    private httpclient: HttpClient,
    private userservice: UserService,
    public pushMessageHandler: PushMessageHandlerService,
    public translateService: RgiRxTranslationService,
    public modalService: ModalService,
    @Inject('environment') private apiconf: any) {
    super(httpclient, userservice, apiconf);
  }

  getEnumsConfig$(enums: any): Observable<any> {
    return super.getEnumsConfig$(enums).pipe(
      map(res => {
        return {
          enums: this.mapEnums(res),
          serviceInfo: res.serviceInfo
        };
      })
    );
  }


  private mapEnums(res) {
    return res.enums.map(e => {
      if (e.code === 'anag.Tipo professione') {
        return {
          code: e.code,
          values: e.values.filter(professions => !this.excludedProfessionTypeCodes.includes(professions.codice))
        };
      }
      return e;
    });
  }

  checkPartyKey$(partyKeyConfigFilter: AnagPartyKeyService) {

    return new Observable<AnagPartyKeyService>((observer) => {
      this.pushMessageHandler.clearTag('taxIdValidation-tag');
      let extension = partyKeyConfigFilter.partyKey.extensionSubject;
      partyKeyConfigFilter.partyKey.extensionSubject = undefined;
      super.checkPartyKey$(partyKeyConfigFilter).subscribe((checkPartyKeyResponse) => {
        if (((checkPartyKeyResponse as any).outcome === '' || (checkPartyKeyResponse as any).outcome == null) && partyKeyConfigFilter.partyKey.country === 'ES') {
          partyKeyConfigFilter.partyKey.extensionSubject = extension;
          this.checkTaxAgency$(partyKeyConfigFilter).subscribe((checkTaxAgencyResponse) => {
            if (checkTaxAgencyResponse.status === "KO") {
              (checkPartyKeyResponse as any).outcome = this.getEvaluetedPartyKey(partyKeyConfigFilter).label + " invalid.";
            } else if (checkTaxAgencyResponse.status === "TECHNICAL_KO") {
              this.notifyValidationMsg('Technical error on ' + this.getEvaluetedPartyKey(partyKeyConfigFilter).label + ' validation.');
            }
            observer.next(checkPartyKeyResponse);
            observer.complete();
          }, error => observer.error(error));
        } else {
          observer.next(checkPartyKeyResponse);
          observer.complete();
        }
      }, error => observer.error(error));
    });
  }

  notifyValidationMsg(stringMsg) {
    this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
      tag: 'taxIdValidation-tag',
      status: 'warning',
      options: { icon: 'rgi-ui-icon-alert' },
      dismissible: false
    }));
  }

  saveParty(anagApiCreateSubject: AnagApiCreateSubject): Observable<AnagApiSaveSubjectResponse> {
    return new Observable<AnagApiSaveSubjectResponse>((observer) => {
      super.saveParty(anagApiCreateSubject).subscribe((resp) => {
        console.log(resp.outcome);
        if (resp.outcome && resp.outcome.length > 0) {
          const evalKey = this.getEvaluetedPartyKeyBySubject(anagApiCreateSubject.subject);
          if (resp.outcome[0] === "EXTAXCHECKKO") {
            const savedModal = this.modalService.openComponent(AnagDialogComponent, {message : 'Invalid ' + evalKey.label});
            savedModal.modal.enableClickBackground = false;
            throw "Invalid taxId";
          } else if (resp.outcome[0] === "EXTAXCHECKTECKO") {
            const savedModal = this.modalService.openComponent(AnagDialogComponent, {message : 'Technical error on ' + evalKey.label + " validation"});
            savedModal.modal.enableClickBackground = false;
          } else if (resp.outcome[0] === "EXTAXCHECKKODOC") {
            this.translateService.translate('_IIAB_._ANAG_._TAX_ID_DIFFERENT_FROM_DOCUMENT_ID_').subscribe(errorMessage => {
              const savedModal = this.modalService.openComponent(AnagDialogComponent, {message : errorMessage});
                savedModal.modal.enableClickBackground = false;
            });
            throw "Invalid taxId, different from identification documentId";
          }
        }
        observer.next(resp);
        observer.complete();
      }), error => observer.error(error);
    });
  }


  getEvaluetedPartyKey(partyKeyConfigFilter: AnagPartyKeyService) {
    return partyKeyConfigFilter.partyKey?.key1?.value ? partyKeyConfigFilter.partyKey.key1 :
      partyKeyConfigFilter.partyKey?.key2?.value ? partyKeyConfigFilter.partyKey.key2 :
        partyKeyConfigFilter.partyKey?.key3?.value ? partyKeyConfigFilter.partyKey.key3 : null
  }

  getEvaluetedPartyKeyBySubject(party: AnagApiParty) {
    return party.partyKey[0]?.key1?.value ? party.partyKey[0].key1 :
    party.partyKey[0]?.key2?.value ? party.partyKey[0].key2 :
    party.partyKey[0]?.key3?.value ? party.partyKey[0].key3 : null
  }

  checkTaxAgency$(partyKeyConfigFilter: AnagPartyKeyService): Observable<any> {

    if (partyKeyConfigFilter.partyKey.extensionSubject) {
      const partyKeyExt: AnagPartyKeyExt = JSON.parse(partyKeyConfigFilter.partyKey.extensionSubject) as AnagPartyKeyExt;
      const evalKey = this.getEvaluetedPartyKey(partyKeyConfigFilter)
      let params = new HttpParams();
      params = params.append('name', partyKeyExt.name);
      params = params.append('surname', partyKeyExt.surname);
      params = params.append('regName', partyKeyExt.denomination);
      params = params.append('key', evalKey ? evalKey.value : null);

      return this.httpclient.get(`${this.hostPath}/iiab-sp/tax/agency`, { params });
    }
  }

}

