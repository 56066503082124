import { TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';

declare const System: System;
interface System {
  import(request: string): Promise<any>;
}

/**
 * @deprecated ngx-translate is deprecated and will be removed in the future
 * migrate to @rgi/rx/i18n
 */
export class MergeTranslationsLoader implements TranslateLoader {
  static catalog;
  private catalogLang = {};

  static setCatalog(catalogParam: any) {
    MergeTranslationsLoader.catalog = catalogParam;
  }

  getTranslation(lang: string): Observable<any> {
    const catalogLangMap = MergeTranslationsLoader.catalog[lang];
    Object.keys(catalogLangMap).forEach(key => {
      this.catalogLang[key] = catalogLangMap[key]['$$noContext'][0];
    });
    const langImport = import(`../assets/i18n/${lang}.json`);
    const langThen = langImport.then((dictionaryLang) => {
      Object.keys(dictionaryLang).forEach((keyElement) => {
        this.catalogLang[keyElement] = dictionaryLang[keyElement];
      });
      return this.catalogLang;
    });
    return from(langThen);
  }
  static addToCatalogFromJson(lang: string, json: any) {
    for (const jsonKey in json.default) {
      MergeTranslationsLoader.catalog[lang][jsonKey] = {$$noContext: [json.default[jsonKey]]};
    }
  }
}

export class LoggingMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const message = `[MISSING TRANSLATION]: ${params.key}`;
    //console.warn(message);
    return params.key;
  }
}

