import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaiOpeningModule } from '@rgi/digital-claims-angular';
import { CaiOpeningClaimUtility } from '@rgi/digital-claims-angular';
import { CaiOpeningClaimExtUtility } from './state-service/utility/cai-opening-claim-ext.utility';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CaiOpeningModule
  ],
  providers: [
    { provide: CaiOpeningClaimUtility, useExisting: CaiOpeningClaimExtUtility },
  ]
})
export class CaiOpeningExtModule { }
