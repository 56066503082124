import {TableSchema} from '@rgi/rx/ui';

export const CLAIMS_BM_LEVEL_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'bmInitial',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._INITIAL_BM_LEVEL_'
    },
    {
      name: 'bmActual',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._ACTUAL_BM_LEVEL_'
    }
  ],
  header: ['bmInitial', 'bmActual']
};
