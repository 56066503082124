import {TableSchema} from '@rgi/rx/ui';

export const CLAIMS_HIGH_LEVEL_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'policyNumber',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._POLICY_NUMBER_'
    },
    {
      name: 'inceptionDate',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._INCEPTION_DATE_'
    },
    {
      name: 'cancellationDate',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._CANC_DATE_',
    },
    {
      name: 'licensePlate',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._LICENSE_PLATE_'
    },
    {
      name: 'vehicleType',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._VEHIC_TYPE_'
    },
    {
      name: 'date',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._DATE_'
    },
    {
      name: 'material',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._MATERIAL_'
    },
    {
      name: 'bodily',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._BODILY_'
    }
  ],
  header: ['policyNumber', 'inceptionDate', 'cancellationDate' , 'licensePlate', 'vehicleType', 'date', 'material', 'bodily']
};
