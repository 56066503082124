// eslint-disable-next-line @typescript-eslint/ban-types
export function registerCommunnicationController(angular: angular.IAngularStatic, name: string, getDirective: Function) {

    const claimsModule = angular.module('claims-communication' + name, []);

    claimsModule.controller('ClaimsCommunication' + name + 'Ctrl', ClaimsCommunicationCtrl);
    claimsModule.service('ClaimsCardService', function () {
        let card = null;
        this.getCard = function () { return card; };
        this.setCard = function (c) { card = c; };
    });

    ClaimsCommunicationCtrl.$inject = [
        '$scope', '$compile', '$timeout', 'ClaimsCardService'
    ];
    function ClaimsCommunicationCtrl(
        $scope, $compile, $timeout, claimsCardService) {

        $scope.directive = getDirective();

        claimsCardService.setCard($scope.card);

        $timeout(
            function () {
                const divElement = angular.element(document.querySelector('#' + $scope.directive.name + 'Directive'));
                const htmlElement = angular.element('<claims-' + name + ' data-qa="claims-entry-{{card.id}}" [card]="card"></claims-' + name + '>');
                divElement.append(htmlElement);
                $compile(divElement)($scope);
            }
        );
    }

    // Refactoring
    angular.module('claims-cards').requires.push('claims-communication' + name);

    return 'ClaimsCommunication' + name + 'Ctrl';
}