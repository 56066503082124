import {HttpInterceptor} from '@angular/common/http';
import {RGI_RX_HTTP_INTERCEPTORS} from '@rgi/rx/http';
import {RGI_RX_AUTH_HTTP_INTERCEPTORS} from '@rgi/rx/auth';
import {RGI_RX_PASSPORTAL_INTERCEPTORS} from '@rgi/rx/portal';
import {ApiNotificationInterceptor, SSOInterceptor} from '@rgi/ng-passpropro-core';
import {PROJECT_HTTP_INTERCEPTORS} from './interceptors';
import {KarmaInterceptor} from '@rgi/life-postsales-card';
import {AnalyticsInterceptor} from '@rgi/analytics';



const whiteListInterceptor= [
    ...RGI_RX_HTTP_INTERCEPTORS.map(r => r.useClass),
    ...RGI_RX_AUTH_HTTP_INTERCEPTORS.map(r => r.useClass),
    ...RGI_RX_PASSPORTAL_INTERCEPTORS.map(r => r.useClass),
    ...PROJECT_HTTP_INTERCEPTORS.map(r => r.useClass),
    SSOInterceptor, // todo encapsulate this with RgiRxHttpClientWrapper in ng-passpropro-core
    ApiNotificationInterceptor, // todo encapsulate this with RgiRxHttpClientWrapper in ng-passpropro-core
    KarmaInterceptor, // todo it's still required?,
    AnalyticsInterceptor

]

export function verifyInterceptors(httpInterceptors: HttpInterceptor[]) {
    const notAllowedInterceptors = httpInterceptors.filter(i => {
        return !whiteListInterceptor.find(
            allowed => {
                return allowed.name === i.constructor.name
            }
        )
    });
    if (notAllowedInterceptors.length > 0) {
        console.error('Detected non expected HTTP_INTERCEPTORS: ', notAllowedInterceptors);
    }
}

