import { ClaimOpeningComponent } from '@rgi/digital-claims-angular/';
import { CardService } from '@rgi/digital-claims-common-angular';

export function registerClaimOpeningCard(
    cardService: CardService
) {
    /*
    const title = 'New FNOL';
    const moduleName = 'claim-opening';

    cardService.registerCard(
        moduleName,
        title,
        ClaimOpeningComponent
    );
    */

    /*
    console.log('registerClaimOpeningCard - ignored module');
    */

    /*
    registerCard(
        moduleName,
        title,
        ClaimOpeningComponent,
        angular
    );
    */

}
