/* "Barrel" of Http Interceptors */
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorInterceptor} from './error.interceptor';
import {ExpireInterceptor} from './expire.interceptor';

/** Http interceptor providers in outside-in order */
export const PROJECT_HTTP_INTERCEPTORS = [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ExpireInterceptor, multi: true}
];

