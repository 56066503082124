import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import { ITDeeplinkTranslations } from './it';
import { ENDeeplinkTranslations } from './en';
import { ESDeeplinkTranslations } from './es';

export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ITDeeplinkTranslations);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ENDeeplinkTranslations);
  });
}



export function loadES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ESDeeplinkTranslations);
  });
}

export const EXT_DEEPLINK_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  },
  {
    load: loadES,
    locale: 'es'
  }
];
