import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';
import {LogLevel, rgiRxBrowserPlatformLogger} from "@rgi/rx";
import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeIt);
registerLocaleData(localeEs);

platformBrowserDynamic([
    rgiRxBrowserPlatformLogger({
      level: environment.production ? LogLevel.ERROR : LogLevel.TRACE
    }),
    {provide: LOCALE_ID, useValue: 'it'},
  ]
).bootstrapModule(AppModule)
  .catch(err => console.log(err));