import {Component, EventEmitter, Inject, Output, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {
  AmendmentService,
  ApiQuotation, CustomModalService, LiensService,
  Modal, ParameterService,
  PartiesService,
  ProposalComponent, ProposalService, QuestionnaireDnService,
  QuestionnaireService, QuotationService, QuoteService, RoutingService, StateService, SubstitutionService,
  PassProductsService, ElaborateCustomProperties, Warning
} from '@rgi/motor-issue-card';
import { MessageModalComponent } from 'src/app/components/message-modal/message-modal.component';
import { ApiQuotationExt } from 'src/app/models/api-models/api-quotation-ext';
import { ButtonModalModel, ProposalModalUserChoice } from 'src/app/models/buttons.model';
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Message, ModalService, OperatorService} from "@rgi/portal-ng-core";
import {AuthorizationsSearchService} from "@rgi/authorizations-card";
import {OtpService} from "@rgi/iiab-portal-ext";
import { ClaimsHistoryServices } from 'src/app/ext/claims/claims-history-services/claims-history-services';
import { ClaimsHistoryModalComponent } from 'src/app/ext/claims/claims-history-modal/claims-history-modal.component';
import { RgiRxUserAuthorizationService } from '@rgi/rx/auth';
import {ModalService as modalRx} from "@rgi/rx/ui";

@Component({
  selector: 'mic-proposal-ext',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss']
})
export class ProposalExtComponent extends ProposalComponent  {

  private $subscriptionsList: Subscription[] = [];
  showOtpDocuments = true;
  showClaimsHistoryButton: boolean = false;

  @ViewChild('modalNextOrBack', {
    read: ViewContainerRef,
    static: true
  }) variablesModalViewContainerRef: ViewContainerRef;

  @ViewChild('modalClaimsHistoryOverlay', {
    read: ViewContainerRef,
    static: true
  }) claimsHistoryModalViewContainerRef: ViewContainerRef;

  @Output()
  eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  buttons: ButtonModalModel[] =
    [{label: this.translate.instant('modal_button_proposalGoBack'), eventCode: '2'},
    {label: this.translate.instant('modal_button_proposalGoAHead'), eventCode: '1'}];

  errorButton: ButtonModalModel[] =
    [{label: 'chiudi', eventCode: ''}];

  constructor(
      protected partiesService: PartiesService,
      protected questionnaireService: QuestionnaireService,
      protected parametersService: ParameterService,
      protected bondService: LiensService,
      protected proposalService: ProposalService,
      protected translate: TranslateService,
      protected customModalService: CustomModalService,
      protected modalService: ModalService,
      protected operatorervice: OperatorService,
      protected routingService: RoutingService,
      protected stateService: StateService,
      protected questionnaireDnService: QuestionnaireDnService,
      protected authorizationsSearchService: AuthorizationsSearchService,
      protected substitutionService: SubstitutionService,
      protected quoteService: QuoteService,
      protected quotationService: QuotationService,
      protected amendmentService: AmendmentService,
      protected passProductService: PassProductsService,
      protected elaborateCustomProperties: ElaborateCustomProperties,
      protected otpService: OtpService,
      @Inject('policySummaryModalComponent') policySummaryModalComponent: Type<Modal>,
      protected claimsHistoryServices: ClaimsHistoryServices,
      protected userAuthorizationService: RgiRxUserAuthorizationService,
	  protected modalRx: modalRx
  ) {
    super(partiesService, questionnaireService, parametersService, bondService, proposalService,
        translate, customModalService, modalService, operatorervice, routingService, stateService, questionnaireDnService
        ,authorizationsSearchService, substitutionService, quoteService, quotationService, amendmentService, passProductService, elaborateCustomProperties, modalRx, policySummaryModalComponent);
  }

  ngOnInit() {
    super.ngOnInit();
    this.$subscriptionsList.push(this.otpService.callOtpAbilitation().subscribe(() => this.showOtpDocuments = this.otpService.otpAbilitation));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.$subscriptionsList.forEach(subscription => subscription.unsubscribe());
  }

  getContractId(): string{
    if (this.proposalService){
      return this.proposalService.getContractId();
    }
    return undefined;
  }

  openClaimsHistory() {
    const modal = this.customModalService.openModal(this.claimsHistoryModalViewContainerRef, ClaimsHistoryModalComponent);
    modal.instance.contractId = this.proposalService.getApiContract() ? this.proposalService.getApiContract().id : null;
  }

  afterGetProposalState(data) {
    super.afterGetProposalState(data);
    this.checkClaimsHistoryToShow();
  }

  checkClaimsHistoryToShow() {
    if (this.getContractId() && this.isAmendment && this.userAuthorizationService.isAuthorizedFor('claims.history.show')) {
      this.claimsHistoryServices.getClaimsHistoryChanged(this.getContractId()).subscribe((resp) => {
        if (resp && resp.changeHistory) {
          this.showClaimsHistoryButton = true;
        }
      }, error => console.error(error));
    }
  }

  onSubscriberChangedExt(onlyEntitled?: boolean): void {
    this.onSubscriberChanged(onlyEntitled);
  }
}
