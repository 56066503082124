import { Component, ComponentRef, EventEmitter, Input } from '@angular/core';
import { Modal } from '@rgi/portal-ng-core';
import { OnModalClose } from '@rgi/rx/ui';

@Component({
  selector: 'app-claims-history-modal',
  templateUrl: './claims-history-modal.component.html',
  styleUrls: ['./claims-history-modal.component.scss']
})
export class ClaimsHistoryModalComponent implements Modal {

  componentRef: ComponentRef<any>;

  @Input() contractId: any;

  close() {
    this.componentRef.destroy();
  }

}
