import {TableSchema} from '@rgi/rx/ui';

export const CLAIMS_MEDIUM_LEVEL_MTPL_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'description',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._HISTORY_MATRIX_'
    },
    {
      name: 'years',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._YEARS_'
    },
    {
      name: 'claims',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._CLAIMS_',
    },
    {
      name: 'coefficient',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._COEFF_'
    }
  ],
  header: ['description', 'years', 'claims' , 'coefficient']
};

export const CLAIMS_MEDIUM_LEVEL_MOD_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'description',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._HISTORY_MATRIX_'
    },
    {
      name: 'years',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._YEARS_'
    },
    {
      name: 'claimsPerc',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._CLAIMS_PERC',
    },
    {
      name: 'coefficient',
      title: 'EXT_COMPONENTS._CLAIMS_HISTORY_._LABEL_._COEFF_'
    }
  ],
  header: ['description', 'years', 'claimsPerc' , 'coefficient']
};
