import { RgiRxi18nModuleLoadType, RgiRxTranslations } from '@rgi/rx/i18n';
import { PNC_PSALES_IT } from './it';
import { PNC_PSALES_EN } from './en';
import { PNC_PSALES_ES } from './es';


export function loadIT(): RgiRxi18nModuleLoadType {
    return new Promise(resolve => {
        resolve(PNC_PSALES_IT);
    });
}

export function loadEN(): RgiRxi18nModuleLoadType {
    return new Promise(resolve => {
        resolve(PNC_PSALES_EN);
    });
}

export function loadES(): RgiRxi18nModuleLoadType {
    return new Promise(resolve => {
        resolve(PNC_PSALES_ES);
    });
}

export const PNC_PSALES_TRANSLATIONS_EXT: RgiRxTranslations = [
    {
        load: loadIT,
        locale: 'it'
    },
    {
        load: loadEN,
        locale: 'en'
    },
    {
        load: loadES,
        locale: 'es'
    }
];
