import { TimelineComponent } from '@rgi/digital-claims-angular';
import { registerCard, registerSubCard } from './claims-communication-utility';


export function registerTimelineCard(angular: angular.IAngularStatic) {
  
  
  const title = '_TIMELINE';
  const moduleName = 'timeline';
  const parentCardName = 'claimsFinder';

  // registerCard(
  //   moduleName,
  //   title,
  //   TimelineComponent,
  //   angular);

  registerSubCard(
    moduleName,
    title,
    TimelineComponent,
    parentCardName,
    angular);

}