export const DEEP_LINK_KEY_CONST = {
    isExternal: 'isExternal',
    function: 'function',
    partyId: 'partyId',
    policyNumber: 'policyNumber',
    proposalNumber: 'proposalNumber',
    quotationNumber: 'quotationNumber',
    authorizationId: 'authorizationId',
    authorizationClaimId: 'authorizationClaimId',
    claimId: 'claimId',
    transactionId: 'transactionId',
    caseID: 'caseId',
    salesNode: 'salesnode'
};

export const DEEPLINK_AVAILABLE_FUNCTIONS = [
  {function: 'proposals', entityName: 'proposalNumber', entityType: 'Proposal'},
  {function: 'policies', entityName: 'policyNumber', entityType: 'Policy'},
  {function: 'parties', entityName: 'partyId', entityType: 'Party'},
  {function: 'quotations', entityName: 'quotationNumber', entityType: 'Quotation'},
  {function: 'transactions', entityName: 'transactionId', entityType: 'Transaction'},
  {function: 'claims', entityName: 'claimId', entityType: 'Claim'},
  {function: 'authorizations', entityName: 'authorizationId', entityType: 'Authorization'}
];