import {DeeplinkTranslationKeys} from './deeplink-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
export const ESDeeplinkTranslations: DeeplinkTranslationKeys = {
    _DEEP_LINK_: {
        _NOT_FOUND_: 'ES_Not found',
        _CLOSE_: 'Cerrar',
        _SYSTEM_MESSAGE_: 'Mensaje del sistema'
    }
}
