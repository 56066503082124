import { NgModule } from "@angular/core";
import { RgiPncPostsalesModule } from "@rgi/pnc-postsales";
import { PNC_PSALES_TRANSLATIONS_EXT } from "./i18n/translations";
import { RgiRxI18nModule } from "@rgi/rx/i18n";

@NgModule({
    declarations: [],
    exports: [],
    imports: [
        RgiPncPostsalesModule,
        RgiRxI18nModule.forRoot(PNC_PSALES_TRANSLATIONS_EXT)
    ],
    providers: [
    ]
})

export class RgiPncPostsalesModuleExt {

}