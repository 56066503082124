import { ChatComponent } from '@rgi/digital-claims-angular/';
import { CardService } from '@rgi/digital-claims-common-angular';


export function registerChatCard(
    cardService: CardService
) {

    /*
    const title = 'Chat';
    const moduleName = 'chat';
    const moduleNameSub = 'chatSubCard';
    const parentCardName = 'claimsFinder';

    cardService.registerCard(
        moduleName,
        title,
        ChatComponent
    );

    cardService.registerSubCard(
        moduleNameSub,
        title,
        ChatComponent,
        parentCardName
    );
    */
}
