import { Component, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AmendmentService, AssetComponent, AssetService, ClauseService, CommonService, ErrorMessagesService, InsuranceBackgroundService, PartiesService, ProposalService, RoutingService, StateService, VariablesService } from "@rgi/motor-issue-card";

@Component({
    selector: 'mic-asset-ext',
    templateUrl: './asset.component.html',
    styleUrls: ['./asset.component.scss']
})
export class AssetExtComponent extends AssetComponent {
    constructor(
        protected translate: TranslateService,
        protected commonService: CommonService,
        protected proposalService: ProposalService,
        protected stateService: StateService,
        protected insuranceBackgroundService: InsuranceBackgroundService,
        protected clauseService: ClauseService,
        protected routingService: RoutingService,
        protected variablesService: VariablesService,
        protected assetService: AssetService,
        protected errorMessagesService: ErrorMessagesService,
        protected partiesService: PartiesService,
        protected amendmentService: AmendmentService) {
            super(translate,commonService,proposalService,stateService,insuranceBackgroundService,clauseService,routingService,variablesService,assetService,errorMessagesService,
                partiesService, amendmentService
            );
    }

    ngOnInit(): void {
        super.ngOnInit();
    }
}