import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { RgiRxDatePickerModule, RgiRxExpansionModule, RgiRxFormElementsModule, RgiRxNativeDateTimeModule } from '@rgi/rx/ui';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { OccurrenceDataModifyExtComponent } from './occurrence-data-modify-ext.component';
import { OccurrenceDataModifyModule, OCCURRENCE_DATA_ROUTES, OccurrenceDataModifyComponent, DscComponentModule } from '@rgi/digital-claims-angular';
import { RgiRxRoutingModule } from '@rgi/rx/router';
import { Routes } from '@angular/router';
import { DscAdditionalDataExtComponent } from '../dsc-additional-data/dsc-additional-data-ext.component';

OCCURRENCE_DATA_ROUTES[0].component = OccurrenceDataModifyExtComponent;

@NgModule({
    declarations: [
        OccurrenceDataModifyExtComponent,
        DscAdditionalDataExtComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        RgiRxFormElementsModule,
        RgiRxExpansionModule,
        RgiRxDatePickerModule,
        RgiRxNativeDateTimeModule,
        DscComponentModule,
        RgiRxI18nModule,
        RgiRxRoutingModule.forRoot(OCCURRENCE_DATA_ROUTES)
    ],
    providers: [
        { provide: OccurrenceDataModifyComponent, }
    ]
})
export class OccurrenceDataModifyExtModule {

}
