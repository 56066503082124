import { ANAG_FUNCTIONS, ANAG_ROUTE_HOME, FIELD, FIELD_ANAG_BIRTH_DATE, FIELD_ANAG_BIRTH_NAME, FIELD_ANAG_CLIENT_NUMBER, FIELD_ANAG_COWORKER, FIELD_ANAG_EMAIL, FIELD_ANAG_INTERNATIONAL_PREFIX, FIELD_ANAG_LOCAL_PREFIX, FIELD_ANAG_NAME, FIELD_ANAG_PARTY_NUMBER, FIELD_ANAG_PARTY_TYPE, FIELD_ANAG_PHONE, FIELD_ANAG_RESIDENCE, FIELD_ANAG_SUBSYSTEM, FIELD_ANAG_SURNAME, FIELD_ANAG_TAB_PARTY_MANAGEMENT, FIELD_ANAG_UNIQUE_KEY } from "@rgi/anag";
import { AnagHomeComponentExt } from "../components/home/anag-home.component-ext";

export const ANAG_EXT_FIELD_POLICY_NUMBER = {
    name : 'policyNumber',
    label : 'Policy Number',
    visible : 1,
    parent: 'anagTabGestione',
    type : 'text'
} as FIELD;

export const ANAG_EXT_FIELD_PLATE = {
    name : 'plate',
    label : 'Plate',
    visible : 1,
    parent: 'anagTabGestione',
    type : 'text'
} as FIELD;

export const FIELD_ANAG_TAB_PARTY_MANAGEMENT_EXT = (function () {
    FIELD_ANAG_TAB_PARTY_MANAGEMENT.fields.push(ANAG_EXT_FIELD_POLICY_NUMBER);
    FIELD_ANAG_TAB_PARTY_MANAGEMENT.fields.push(ANAG_EXT_FIELD_PLATE);
    return FIELD_ANAG_TAB_PARTY_MANAGEMENT;
  })();

export const ROUTES_MODAL_HOME_EXT = 'rgi.anag.modal.home-ext';
export const ANAG_ROUTE_MODAL_EXT_HOME = {
    route: ROUTES_MODAL_HOME_EXT,
    component: AnagHomeComponentExt,
    providers: ANAG_ROUTE_HOME.providers
};