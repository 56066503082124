import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LifeCardModule } from '@rgi/life-issue-card';
import { LifePostsalesModule } from '@rgi/life-postsales-card';
import { PlcGraphicFixExt } from '../ext/utils-ext/plc-graphicFix-utils';

@NgModule({
  imports: [
    CommonModule,
    LifeCardModule,
    LifePostsalesModule
  ],
  providers: [
    { provide: 'plcGraphicFix', useValue: PlcGraphicFixExt },
    { provide: 'RGI_ANAG_PORTAL_AJS_FLOW', useValue: false }
  ]
})
export class LifeModule { }
