import {PncPostsalesTranslationsKeys} from './pnc-psales-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const PNC_PSALES_ES: PncPostsalesTranslationsKeys = {
  _PCPSALES_: {
    _BTN_: {
      _BACK_: 'Atras',
      _CALCULATE_: 'Calcular',
      _CONFIRM_: 'Confirmar',
      _CONTINUE_: 'Continuar',
      _DETAIL_: 'Detalle',
      _DOCS_: 'Gestión de documentos',
      _END_: 'Fin',
      _EXIT_: 'Salir',
      _PRINT_: 'Imprimir',
      _RESET_: 'Restablecer'
    },
    _LABEL_: {
      _AMOUNT_: 'Cantidad',
      _ANTIRACKET_: 'Antiraqueta',
      _APPENDIX_EFFECT_DATE_: 'Fecha de vigencia del Apéndice',
      _APPENDIX_EXPIRY_DATE_: 'Fecha de vencimiento del apéndice',
      _CANCELLATION_NOTIFICATION_DATE_: 'Fecha de notificación de cancelación',
      _CANCELLATION_REASON_: 'Motivo de la cancelación',
      _CHARGES_: 'Cargos',
      _CHARGES_INTERESTS_: 'Cobra Intereses',
      _COLLECTED_COMMISSIONS_: 'Comisiones cobradas',
      _EFFECT_DATE_: 'Fecha de entrada en vigor',
      _EFFECT_HOUR_: 'Hora efectiva',
      _EXCLUSION_NOTIFICATION_DATE_: 'Fecha de notificación de exclusión',
      _EXCLUSION_REASON_: 'Motivo de exclusión',
      _FEES_: 'Tarifas',
      _GROSS_: 'Bruto',
      _INSURED_RISKS_: 'Riesgos asegurados',
      _ISSUE_DATE_: 'Fecha de emisión',
      _NET_: 'Neto',
      _NET_INTERESTS_: 'Intereses Netos',
      _NOT_INSURED_RISKS_: 'Riesgos no asegurad',
      _NOTES_: 'Notas',
      _OPERATION_PREMIUM_: 'Operación Premium',
      _PRINT_DOCUMENTS_: 'Imprimir documentos',
      _PURCHASED_COMMISSIONS_: 'Comisiones compradas',
      _RATE_: 'Tarifa',
      _RCA_NET_: 'Red RCA',
      _RIGHTS_: 'Derechos',
      _SSN_: 'NSS',
      _TAX_: 'Impuesto',
      _TAXABLE_: 'Sujeto a impuestos',
      _TAXES_: 'Impuestos'
    },
    _MSG_: {
      _ALERT_ACCOUNTING_REVERSAL_: 'No se puede realizar la reversión, porque el movimiento tiene un valor en estado diferente al AR. Proceder manualmente con la reversión del cobro del valor vinculado.\n',
      _CONFIRM_ACCOUNTING_REVERSAL_: 'Atención, además del movimiento, también se cancelará la Cobranza. ¿Quieres continuar?',
      _CONFIRM_OPERATION_: '¿Confirma la operación {{opDescr}} en la política n. {{policyNumber}}?',
      _CONFIRM_REVERSAL_: '¿Confirmas que quieres cancelar el movimiento? Esta cancelación es definitiva y no se puede restablecer el movimiento.',
      _NO_TECH_ACC_FOUND_: 'No se encontró contabilidad técnica antes de la fecha de vigencia de la variación ni se recopiló después',
      _NODE_TO_REQUIRED_: 'El nodo receptor es requerido',
      _NOT_ALLOWED_REVERSAL_: 'Movimiento de inversión no permitido.',
      _OPERATION_FOR_POLICY_OK_: 'La operación {{opDescr}} en la política {{policyNumber}} se ejecutó exitosamente',
      _SAME_BRANCHES_: 'Las sucursales transmisoras y receptoras son las mismas',
      _SAME_NODE_: 'Los nodos de transferencia y recepción son los mismos',
      _WARNING_ACCOUNTING_REVERSAL_: 'Atención, además del movimiento, también se cancelará la Cobranza.',
    },
    _TITLE_: {
      _PREMIUM_REPAYMENT_: 'Reembolso de la prima',
      _TECH_ACC_LIST_: 'Lista de Contabilidad Técnica',
      _MOVEMENTS_LIST_: 'Lista de Movimientos'
    },
    _STEPS_: {
      _CANCELLATION_DATA_: 'Datos de cancelación',
      _EXCLUSION_DATA_: 'Datos de exclusión',
      _TECHNICAL_ACCOUNTING_: 'Contabilidad Técnica',
      _SUMMARY_: 'Resumen',
      _VARIATION_DATES_: 'Fechas de variación',
      _MOVEMENTS_: 'Movimientos',
      _SUPPLEMENTARY_TECH_ACC_: 'Prima Contable Técnica Suplementaria',
      _POLICY_TRANSFER_DATA_: 'Datos de transferencia de política'
    },
    _SESSION_TITLE_: {
      _APPLICATION_EXCLUSION_: 'Exclusión de aplicación n. {{policyNumber}}',
      _MOVEMENTS_REVERSAL_: 'Exclusión de aplicación n. {{policyNumber}}',
      _POLICY_CANCELLATION_: 'Inversión de movimientos n. {{policyNumber}}',
      _POLICY_REACTIVATION_: 'Cancelación de póliza n. {{policyNumber}}',
      _POLICY_TRANSFER_: 'Transferencia de póliza n. {{policyNumber}}',
      _TECHNICAL_ACCOUNTING_APPENDIX_: 'Política del Apéndice Técnico Contable n. {{policyNumber}}',
       _CLAIMS_INSURANCE_CERTIFICATE_: 'Póliza de Certificado de Seguro de Siniestros n. {{policyNumber}}'
    }
  },
  _RGIPNC_: {
    _BTN_: {
      _CANCEL_: 'Cancelar',
      _CLOSE_: 'Cerca',
      _CONFIRM_: 'Confirmar'
    },
    _LABEL_: {
      _ACCESSORIES_: 'Accesorios',
      _ACQUIRED_CHARGES_: 'Cargos adquiridos',
      _ACQUIRED_COMMISSION_: 'Comisión adquirida',
      _ACTION_: 'Acción',
      _ACTIONS_: 'Acciones',
      _AMOUNT_: 'Cantidad',
      _ANNUAL_PREMIUM_: 'Prima anual',
      _ANTIRACKET_: 'Fiscalidad contra el blanqueo de capitales',
      _APPENDIX_: 'Apéndice',
      _BRANCH_FROM_: 'Transfiriendo sucursal',
      _BRANCH_TO_: 'Sucursal receptora',
      _CANCELLED_: 'Cancelado',
      _CODE_: 'Código',
      _COLLECTED_CHARGES_: 'Cargos cobrados',
      _COLLECTED_COMMISSION_NET_: 'Comisión cobrada (neta)',
      _COMMISSIONS_: 'Comisiones',
      _ACCOUNTING_DETAILS_: 'Detalles contables',
      _GROSS_: 'Bruto',
      _CURRENCY_: 'Moneda',
      _DESCRIPTION_: 'Descripción',
      _EFFECT_: 'Efecto',
      _EFFECT_DATE_: 'Fecha de entrada en vigor',
      _EFFECT_HOUR_: 'Hora del efecto',
      _INCEPTION_INSTALLMENT_: 'Cuota en la fecha de inicio',
      _INSTALLMENT_INTEREST_: 'Interés de la cuota',
      _INSTALLMENT_PREMIUM_: 'Prima a plazos',
      _INSURANCE_TAX_: 'Impuesto sobre seguros',
      _ISSUE_: 'Emitir',
      _NET_: 'Neto',
      _NEXT_INSTALLMENT_PREMIUM_: 'Próxima prima',
      _NODE_: 'Nodo',
      _NODE_FROM_: 'Transfiriendo Nodo',
      _NODE_TO_: 'Nodo receptor',
      _NUMBER_: 'Número',
      _POLICY_DETAILS_: 'Detalle de la prima de la póliza',
      _TOTAL_PREMIUMS_: 'Prima total',
      _RATE_: 'Tarifa',
      _RIGHTS_: 'Derechos',
      _SELECT_NODE_: 'Seleccionar nodo',
      _SIGNED_COMMISSIONS_: 'Comisiones en la fecha de inicio',
      _SSN_: 'NSS',
      _STATUS_: 'Estado',
      _TAXABLE_: 'Sujeto a impuestos',
      _TAXES_: 'Impuestos',
      _TOTAL_: 'Totales',
      _TYPE_: 'Tipo',
      _USER_: 'Usuario',
      _VIEW_: 'Ver',
      _YES_: 'SÍ',
      _NO_: 'NO',
    },
    _TITLE_: {
      _NODE_LIST_: 'Lista de nodos',
      _SYSTEM_MESSAGE_: 'Mensaje del sistema'
    }
  },
  _RE_ISSUE_: {
    _CLOSE_: 'Cerca',
    _CONFIRM_: 'Confirmar',
    _SYSTEM_MESSAGE_: 'Mensaje del sistema'
    },
  _VOID_: 'VACÍO',
};
