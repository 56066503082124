import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    AccountingTransactionsModule,
    ActivityListModule,
    ChatModule,
    ClaimOpeningModule,
    ClaimQuestionnaireModule,
    ElaborationRequestModule,
    ClaimSubjectsModule,
    ExpertsModule,
    ExternalPortfolioExtrainfoModule,
    InvoiceModule,
    LetterTemplatesModule,
    LimitsManagementModule,
    NewPaymentModule,
    OccurrenceDataModifyModule,
    TimelineModule,
    UpdateReserveModule,
    CaiOpeningModule,
    DialogueHandlerModule,
    FivaModule,
    PaymentsDashboardModule
} from '@rgi/digital-claims-angular';
import {CardService} from '@rgi/digital-claims-common-angular';
import {AjsConfiguration} from './ajs-configuration';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        TimelineModule,
        ChatModule,
        ActivityListModule,
        ClaimOpeningModule,
        NewPaymentModule,
        LimitsManagementModule,
        ClaimSubjectsModule,
        AccountingTransactionsModule,
        InvoiceModule,
        ExternalPortfolioExtrainfoModule,
        UpdateReserveModule,
        LetterTemplatesModule,
        ExpertsModule,
        OccurrenceDataModifyModule,
        ClaimQuestionnaireModule,
        CaiOpeningModule,
        ElaborationRequestModule,
        DialogueHandlerModule,
        FivaModule,
        PaymentsDashboardModule
    ],
    providers: [
        { provide: 'RGI_ANAG_PORTAL_AJS_MODAL_OVERRIDE', useValue: true},
        { provide: 'RGI_ANAG_PORTAL_AJS_CARD_OVERRIDE', useValue: true},
    ]

})
export class ClaimsModule {


    constructor(
        private cardService: CardService
    ) {
        AjsConfiguration(this.cardService);
    }
}