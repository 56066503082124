/* upgrade parallelRouting */
import {FactoryProvider} from '@angular/core';

export function pRouterFactory(i: any) {
  return i.get('parallelRouting');
}

const pRouterProvider = {
  provide: 'parallelRouting',
  useFactory: pRouterFactory,
  deps: ['$injector']
};

/* upgrade coreAuthService */
export function coreAuthServiceFactory(i: any) {
  return i.get('coreAuthService');
}

const coreAuthServiceProvider = {
  provide: 'coreAuthService',
  useFactory: coreAuthServiceFactory,
  deps: ['$injector']
};

/* upgrade authService */
export function authServiceFactory(i: any) {
  return i.get('authService');
}

const authServiceProvider = {
  provide: 'authService',
  useFactory: authServiceFactory,
  deps: ['$injector']
};

/* upgrade angularGridInstance */
export function angularGridInstanceFactory(i: any) {
  return i.get('angularGridInstance');
}

const angularGridInstanceProvider = {
  provide: 'angularGridInstance',
  useFactory: angularGridInstanceFactory,
  deps: ['$injector']
};

/* upgrade sessionService */
export function sessionServiceFactory(i: any) {
  return i.get('sessionService');
}

const sessionServiceProvider = {
  provide: 'sessionService',
  useFactory: sessionServiceFactory,
  deps: ['$injector']
};

/* upgrade coreResult */
export function coreResultFactory(i: any) {
  return i.get('coreResult');
}

const coreResultProvider = {
  provide: 'coreResult',
  useFactory: coreResultFactory,
  deps: ['$injector']
};

/* upgrade cardNavigationWFServiceFactory */
export function cardNavigationWFServiceFactory(i: any) {
  return i.get('cardNavigationWFService');
}

const cardNavigationWFServiceProvider = {
  provide: 'cardNavigationWFService',
  useFactory: cardNavigationWFServiceFactory,
  deps: ['$injector']
};

/* upgrade eventServiceServiceFactory */
export function eventServiceFactory(i: any) {
  return i.get('eventService');
}

const eventServiceProvider = {
  provide: 'eventService',
  useFactory: eventServiceFactory,
  deps: ['$injector']
};


export function containerCacheFactory(i: any) {
  return i.get('containerCacheService');
}

const containerCacheProvider = {
  provide: 'containerCacheService',
  useFactory: containerCacheFactory,
  deps: ['$injector']
};

export const PORTAL_AJS_PROVIDERS: FactoryProvider[] = [
    pRouterProvider,
    coreAuthServiceProvider,
    authServiceProvider,
    angularGridInstanceProvider,
    sessionServiceProvider,
    coreResultProvider,
    cardNavigationWFServiceProvider,
    eventServiceProvider,
    containerCacheProvider
]

