import { Component, EventEmitter, Inject, Injectable, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonService, CustomModalService, MagazinesService, PassProductsService, ProposalService, StateService, VariablesService, VehicleDataComponent } from '@rgi/motor-issue-card';
import { MagazineDataFilter } from '@rgi/motor-issue-card/lib/models/domain-models/magazine/magazine-data-filter';
import { Subscription } from 'rxjs';

@Component({
    selector: 'mic-vehicle-ext',
    templateUrl: './vehicle-data.component.html',
    styleUrls: ['./vehicle-data.component.scss']
}) 
export class VehicleDataExtComponent extends VehicleDataComponent {

    private subscriptionsExt: Subscription = new Subscription();


    constructor(
        public formBuilder: FormBuilder,
        public passProductsService: PassProductsService,
        public proposalService: ProposalService,
        public magazineService: MagazinesService,
        public variablesService: VariablesService,
        public commonService: CommonService,
        public stateService: StateService,
        public customModalService: CustomModalService, //il tipo è any in quanto CustomModalService non è esportato da motor-issue-card
        @Inject('SHOW_CHANGE_VEHICLE_ASSET_PAGE') protected showChangeVehicleAssetPage: boolean) {
        super(formBuilder, passProductsService, proposalService, magazineService, variablesService, commonService, stateService, customModalService, showChangeVehicleAssetPage);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    initializeAdvancedFilters() {
        this.filterFirstInitialization();
        let isRegistrationDateValid = this.isRegistrationDateRequired ? this.staticData.registrationDate : true;

        if (this.staticData.model.code !== '' &&
            this.staticData.brand.code !== '' &&
            isRegistrationDateValid
        ) {
            const brandCode = this.staticData.brand.code;
            const modelCode = this.staticData.model.code;
            let registrationDate = this.assetForm.controls.registrationDate.value;
            let registrationMonth = null;
            let registrationYear = null;

            if (registrationDate && !(registrationDate instanceof Date)) {
                registrationDate = new Date(registrationDate);
            }
            if (registrationDate) {
                registrationMonth = registrationDate.getMonth() + 1;
                registrationYear = registrationDate.getFullYear();
            }


            this.getAdvancedFilters(brandCode, modelCode, registrationMonth, registrationYear);
        }
    }

    filterFirstInitialization() {
        this.magazineService.getFilters(null, null, null, null, null, true).subscribe(
            (data: Array<MagazineDataFilter>) => {
                this.advancedFilters = data;
                this.addFilterFieldsToAssetForm();
                this.manageAmendmentFlow();
                if (data.some(field => field.mandatory === false)) {
                    const modelSubscription = this.assetForm.get('model').valueChanges.subscribe(() => {
                        this.manageFilterFields();
                    });
                    this.subscriptionsExt.add(modelSubscription);
                }
            });
    }


    ngOnDestroy() {
        this.subscriptionsExt.unsubscribe();
    }


}
