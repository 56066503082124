import {RgiRxTranslations} from '@rgi/rx/i18n';

export function loadES() {
  return import('./translations_es').then(p => p.ANAG_ES)
}

export function loadEN() {
  return import('./translations_en').then(p => p.ANAG_EN)
}


export const TRANSLATIONS_ANAG: RgiRxTranslations = [
  {
    load: loadES,
    locale: 'es'
  },
  {
    load: loadEN,
    locale: 'en'
  }
];
