import { HostListener, Inject, Injectable } from '@angular/core';
import { DEEPLINK_AVAILABLE_FUNCTIONS, DEEP_LINK_KEY_CONST } from '../constants/deeplink.constants';
import { AnagResourceService, AnagRouteData, AnagIntegrationService, ModalDialogData } from '@rgi/anag';
import { ActiveRoute } from '@rgi/rx/router';
import { RgiRxUserService } from '@rgi/rx/auth';
import { OperatorLight } from '../models/operator.model';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { AuthorizationFilter, AuthorizationsSearchService } from '@rgi/authorizations-card';
import { DeeplinkModalComponent } from '../deeplink-modal/deeplink-modal.component';
import { ModalService } from '@rgi/rx/ui';
import { RgiRxHttpClient, RgiRxHttpClientFactory } from '@rgi/rx/http';
import { RestApiConf } from '@rgi/anag/lib/anag-constants/rest-api-conf';
import { Observable } from 'rxjs';
import { SaveCaseIdResponse } from '../models/deeplink.model';

@Injectable({
    providedIn: 'root'
})
export class DeepLinkService {

    private _storage: Map<string, any>;
    protected httpClient: RgiRxHttpClient;
    protected hostPath: string;
    deepLinkConst = DEEP_LINK_KEY_CONST;

    constructor(
        @Inject('ptfallFilterService') private ptfallFilterService: any,
        @Inject('ptfAllFinderFormService') private ptfAllFinderFormService: any,
        @Inject('ptfAllFinderResultService') private ptfAllFinderResultService: any,
        @Inject('coreResult') private coreResultService: any,
        @Inject('sessionService') private sessionService: any,
        @Inject('PassqqProducts') private passqqProducts: any,
        @Inject('passqqFinderFilterService') private passqqFinderFilterService: any,
        @Inject('PassqqQuoteDetail') private passqqQuoteDetail: any,
        @Inject('accountingSecuritiesJumpServices') private accountingSecuritiesJumpServices: any,
        @Inject('cardPassService') private cardPassService: any,
        @Inject('authService') private authService: any,
        @Inject('claimsFinderFilterService') private claimsFinderFilterService: any,
        @Inject('getSystemProperty') private getSystemProperty: any,
        @Inject('claimsService') private claimsService: any,        
        protected activeRoute: ActiveRoute,
        protected anagResources: AnagResourceService,
        protected anagIntegrationService: AnagIntegrationService,
        protected userService: RgiRxUserService,
        protected translateService: RgiRxTranslationService,
        protected authorizationsSearchService: AuthorizationsSearchService,
        protected modalService: ModalService,
        protected httpClientFactory: RgiRxHttpClientFactory,
        @Inject('environment') protected apiConf: RestApiConf) {

        this._storage = new Map<string, any>();
        this.httpClient = this.httpClientFactory.buildWrapper();
        this.hostPath = this.apiConf.api.portal.host + this.apiConf.api.portal.path;
    }

    @HostListener('window:unload', ['$event'])
    unloadHandler(event) {
        this.clear();
    }
    
    isFromDeeplink(): boolean {
        return this.get(this.deepLinkConst.isExternal) ? true : false;
    }

    public set(key: string, value: any) {
        this._storage.set(key, value);
    }
    
    public get(key: string): any {
        return this._storage.get(key);
    }
    
    public delete(key: string) {
        this._storage.delete(key);
    }
    
    public clear() {
        this._storage.clear();
    } 

    public getNode(): any {
        return this._storage.get(this.deepLinkConst.salesNode);
    }

    setFromExternal(endPoint: any) {
        const urlSplitted = endPoint.split('?');
        const endPointUrl = urlSplitted[0];
            
        DEEPLINK_AVAILABLE_FUNCTIONS.forEach(elem => {
            if (endPointUrl.search(elem.function) > -1) {
                this.set(this.deepLinkConst.isExternal, '1');
                const splitUrl = endPointUrl.split(elem.function+'/');
                if (splitUrl.length > 1) {
                    const entityId = splitUrl[1]; // policy Number, proposal number, ecc
                    this.set('entityId', entityId);
                    this.set(elem.entityName, entityId);
                    this.set('entityType', elem.entityType);                        
                }

                if (urlSplitted.length > 1) {
                    const paramsQs = urlSplitted[1];
                    const parameters = new URLSearchParams(paramsQs);

                    for (const [key, value] of parameters.entries()) {
                        this.set(key, value);
                    }    
                }                
            }
        });
    }

    getSaveCaseIdBody(): any {
        let body = {
            entityId: this.get('entityId'),
            entityType: this.get('entityType'),
            caseId: this.get(this.deepLinkConst.caseID)
        };
        return body;
    }

    redirectTo() {
        if (this.get(this.deepLinkConst.proposalNumber) || this.get(this.deepLinkConst.policyNumber)) {
            this.goContractDetail(this);
        } else if (this.get(this.deepLinkConst.partyId)) {
            this.goPartyDetail();
        } else if (this.get(this.deepLinkConst.quotationNumber)) {
            this.goQuotationDetail(this);
        } else if (this.get(this.deepLinkConst.transactionId)) {
            this.goTransactionDetail(this);
        } else if (this.get(this.deepLinkConst.claimId)) {
            this.goClaimDetail(this);
        } else if (this.get(this.deepLinkConst.authorizationId)) {
            this.goAuthDetail();
        }

    }

    goAuthDetail() {
        this.authorizationsSearchService.getAuthorizationDetailById(this.get(this.deepLinkConst.authorizationId)).subscribe(item => {
            this.authorizationsSearchService.setAuthorization(item);
            const id = this.sessionService.open('authorizationsCard', 'authorizations/detail');

        });
    }

    goContractDetail(deeplinkThis: any) {
        var filter = this.ptfallFilterService.createFilter();
        filter.searchParentNodes = false;
        if (this.get(this.deepLinkConst.policyNumber)) {
            filter.policyNumber = this.get(this.deepLinkConst.policyNumber);
        } else {
            filter.proposalNumber = this.get(this.deepLinkConst.proposalNumber);
        }

        var finderPromise = this.ptfAllFinderFormService.find(filter);
        var finderResultService = this.ptfAllFinderResultService;
        var coreResult = this.coreResultService;
        var sessionService = this.sessionService;
        finderPromise.then(function (result) {
            if (result && result.policies && result.policies.length) {
                var contract = result.policies[0];
                var detailsRoute = finderResultService.getDetailRoute(contract);
                if (detailsRoute) {
                    var idNavigation = sessionService.open('ptfallFinder', detailsRoute, '', true);
                    coreResult.setResult(idNavigation, detailsRoute, contract);
                }
            } else {
                deeplinkThis.showError();
            }
        });
    }

    goPartyDetail() {
        const partyId = this.get(this.deepLinkConst.partyId)
        let row = {objectId: partyId};
        let nodeId = this.userService.getUser<OperatorLight>().salePoint.objectId;
        this.anagResources.getSubjectDetail$(row.objectId, nodeId, null).subscribe(resp=> {
            if (resp && resp.subject) {
                const routeData = new AnagRouteData();
                routeData.party = resp.subject;
                routeData.redirectToMainFlow = false;
                this.anagIntegrationService.openDetailSubject(routeData);
            } else {
                this.showError();
            }
        });
    }

    goQuotationDetail(deeplinkThis: any) {
        var filter = this.passqqProducts.getFilter();
        filter.agencyID = this.userService.getUser<OperatorLight>().salePoint.objectId;
        filter.type = [1];
        filter.quoteID = this.get(this.deepLinkConst.quotationNumber);
        filter.currentPage = '1';
        filter.searchExternalQuotes = false;

        var coreResult = this.coreResultService;
        var sessionService = this.sessionService;
        var passqqQuoteDetail = this.passqqQuoteDetail;
 
        passqqQuoteDetail.get({
            idQuote: filter.quoteID
        }).$promise.then(function (result) {
            if (result) {
                var idNavigation = sessionService.open('passqqFinder', 'detail', '', true);
                coreResult.setResult(idNavigation, 'detail', result);
            } else {
                deeplinkThis.showError();
            }
        });
           
    }

    goTransactionDetail(deeplinkThis: any) {
        const filter = {
            agencyID:  this.userService.getUser<OperatorLight>().salePoint.objectId,
            company: this.getCompanyCode(),
            jumpFunction: 'CA_T01',
            orderBy: '11',
            searchLinkedToProposal: true,
            securityLinkedTo: 2,
            securityNumber: this.get(this.deepLinkConst.transactionId),
            subtree: true
        };
        this.translateService.translate('Technical Accounting Transactions - Finder').subscribe(stringMsg => {
            var objIntegrationPass = this.accountingSecuritiesJumpServices.getObjIntegrationSecuritiesFinder(filter);
            this.cardPassService.createJump({}, objIntegrationPass, stringMsg, 'finder', 'securitiesmanagement');
        });               
           
    }
    getCompanyCode() {
        const operator = this.authService.getOperator();
        return operator.visibleCompanies && operator.visibleCompanies.length ? operator.visibleCompanies[0].code : '000001'
    }

    goClaimDetail(deeplinkThis: any) {
        var filter = this.getBaseFilter(this.authService.getOperator());
        filter['claimNumber'] = this.get(this.deepLinkConst.claimId);
        filter['internalCompanyCode'] = this.getCompanyCode();
        const claimsFinderFilterService = this.claimsFinderFilterService;
        const claimsService = this.claimsService;
        const coreResult = this.coreResultService;
        const sessionService = this.sessionService;
        
        this.getSystemProperty.findProperty('ClaimsConfigSearchLCC').then(function (result) {
            if (result.systemProperties && result.systemProperties.length === 1) {
                const configSearchLCC = result.systemProperties[0].value === 'Enable' ? true : false;
                if(!configSearchLCC) {
                    filter.chcCode = '';
                }
            }

            claimsFinderFilterService.findClaimsPes('tabClaims', filter).then(function (result) {
                if (result &&
                    result.claims &&
                    result.claims.length > 0) {
                    //Add companyCode su result, così da poterlo sempre passare
                    result.companyCodeInput = filter['internalCompanyCode'];
                    claimsService.getClaimsPesDetail(result.claims[0]).then(function (result) {
                        if (result.claim) {
                            //with code 5 the user doesn't have permission
                            if (result.resultComplete && result.resultComplete.resultCode === '5') {
                                deeplinkThis.showError(result.resultComplete.resultMessage);
                            } else {
                                var detailResult = result.claim;
                                var idResult = sessionService.open('claimsFinder', 'detail', '', true);
                                detailResult.idResult = idResult; //appoggio l'id del coreresult per aggiornare l'oggetto in cache 
                                coreResult.setResult(idResult, 'detail', detailResult);                              
                            }
                        } 
                    }).finally();
                } else {
                    deeplinkThis.showError();
                }           
            });
        });      

           
    }

    getBaseFilter(operator: any) {
        if (operator.isUserNetworkClaims) {  
            return {
                chcCode: this.authService.getLiquidationCentreCode()
            };
        } else {
            return {
                managementNode: this.claimsFinderFilterService.resetManagementNode()
            };
        }
    }

    showError(errorMsg: string = null) {
        if (errorMsg) {
            this.modalService.openComponent(DeeplinkModalComponent, new ModalDialogData(errorMsg, false));
        } else {
            this.translateService.translate('_DEEP_LINK_._NOT_FOUND_').subscribe(msg => {
                this.modalService.openComponent(DeeplinkModalComponent, new ModalDialogData(msg, false));
            });
        }       
    }

    saveCaseId(): Observable<SaveCaseIdResponse> {
        let body = this.getSaveCaseIdBody();
        return this.httpClient.post<SaveCaseIdResponse>(`${this.hostPath}/gel/saveCaseId`, body);
    }

    callSaveCaseId() {
        if (this.get(this.deepLinkConst.caseID)) {
            this.saveCaseId().subscribe();
        }        
    }
}