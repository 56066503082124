import { ActivityListComponent } from '@rgi/digital-claims-angular';
import { CardService } from '@rgi/digital-claims-common-angular';

export function registerActivityListCard(
    cardService: CardService
) {
    const title = 'Activity';
    const moduleName = 'activity-list';
    const parentCardName = 'claimsFinder';
    const debugCardsOn = false;

    if (debugCardsOn) {
        //TODO: temporary for debug only, remove later
        cardService.registerCard(
            moduleName,
            title,
            ActivityListComponent);
    } else {
        cardService.registerSubCard(
            moduleName,
            title,
            ActivityListComponent,
            parentCardName);
    }
}
