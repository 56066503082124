import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import { ITExtTranslations } from './it';
import { ENExtTranslations } from './en';
import { ESExtTranslations } from './es';

export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ITExtTranslations);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ENExtTranslations);
  });
}



export function loadES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(ESExtTranslations);
  });
}

export const EXT_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  },
  {
    load: loadES,
    locale: 'es'
  }
];
