import { Injectable } from '@angular/core';
import {HttpHeaders, HttpRequest} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }

  static DISABLE_ERR_INTERCEPT_HEADER = 'X-Disable-Error-Interceptor';

  static getErrorInterceptorDisable(): HttpHeaders {
    return new HttpHeaders().append(UtilitiesService.DISABLE_ERR_INTERCEPT_HEADER, 'true');
  }

  static isErrorInterceptorDisable(request: HttpRequest<any>): boolean {
    return request.headers.has(UtilitiesService.DISABLE_ERR_INTERCEPT_HEADER);
  }

}
