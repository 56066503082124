import {DeeplinkTranslationKeys} from './deeplink-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const ENDeeplinkTranslations: DeeplinkTranslationKeys = {
    _DEEP_LINK_: {
        _NOT_FOUND_: 'Not found',
        _CLOSE_: 'Close',
        _SYSTEM_MESSAGE_: 'System message'
    }
}
