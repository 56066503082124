import {downgradeComponent, downgradeInjectable} from '@angular/upgrade/static';

// Claims

import {ModalComponent} from '@rgi/portal-ng-core';
import {
  AdminExtService,
  OpenIdConnectService,
  RgiAuthOpenIdTokenRefreshService,
  RgiAuthStore
} from "@rgi/iiab-gel-library";
import {DeepLinkService} from './deeplink/services/deeplink.services';
import {DeepLinkLegacyService} from "./deeplink/services/deeplink-legacy.service";

// ANGULARJS CONFIGURATION
declare var angular: angular.IAngularStatic;

export function AjsConfiguration() {

  // Events
  angular.module('core').config(['eventServiceProvider', (evProvider) => {
    evProvider.registerEvent('gettextLanguageChanged', { type: 'i18n' });
  }]);

  

  // Other components
  angular.module('app').directive(
      'pncModal', downgradeComponent({ component: ModalComponent })
  );

  angular.module('auth-oidc', []).factory('RgiAuthStoreAjs', downgradeInjectable(RgiAuthStore));
  angular.module('auth-oidc').factory('OpenIdConnectServiceAjs', downgradeInjectable(OpenIdConnectService));
  angular.module('auth-oidc').factory('RgiAuthOpenIdTokenRefreshServiceAjs', downgradeInjectable(RgiAuthOpenIdTokenRefreshService));
  angular.module('iiab-gel', []).factory('AdminExtService', downgradeInjectable(AdminExtService));
  angular.module('app').factory('DeepLinkService', downgradeInjectable(DeepLinkService));
  angular.module('app').factory('DeepLinkLegacyService', downgradeInjectable(DeepLinkLegacyService));
}
