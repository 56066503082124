import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ApiQuotation, MicHttpService, ProposalService} from '@rgi/motor-issue-card';

@Injectable({
  providedIn: 'root'
})
export class QuotationServiceExt {

  private baseApiUrl: string;
  private baseApiUrlV2: string;
  protected httpClient: HttpClient;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    protected proposalService: ProposalService) {
        this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;

        const newMotorFlowServicesPath = environment.api.portal.nmf;
        this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
        ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
        : environment.api.portal.host + environment.api.portal.path + '/v2';
        this.httpClient = this.micHttpClient.getHttpClient();
  }

  getQuotation(quotation: ApiQuotation, extHeaders: HttpHeaders, contractId: string) {
    if (quotation === null || quotation === undefined) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      if (extHeaders) {
        for (const key of extHeaders.keys()) {
            headers = headers.set(key, extHeaders.get(key));
        }
      }
      const options = { headers };
      return this.httpClient.put<ApiQuotation>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/quotation', quotation, options);
    } else {
      return this.httpClient.put<ApiQuotation>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/quotation', quotation);
    }

  }

}
