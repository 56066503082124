import { Injectable } from '@angular/core';
import { AnagApiParty, AnagConfigService, AnagConfiguration} from '@rgi/anag';

@Injectable()
export class AnagConfigServiceExt extends AnagConfigService {
  isFieldBirthNameValid(config: AnagConfiguration, party: AnagApiParty) {
    if (this.isFieldMandatory(config.partyConfig.birthName) && this.isNieValued(party)) {
      // birthName is not mandatory if nie is valued
      return true;
    } else {
      return (!this.isFieldMandatory(config.partyConfig.birthName) || !!party.birthName);
    }
  }

  isNieValued(party: AnagApiParty) {
    return party.partyKey.find(elem => elem.key2 && elem.key2.value);
  }

  /*isDocumentValidConfig(config: AnagConfiguration, docs: AnagDocument[], isValid: boolean) {
    const docConfig = config.partyConfig.document;
    let valid;
    if (!!docs && docs.length > 0) {
      docs?.every(doc => {
        valid =
          (!this.isFieldMandatory(docConfig.authoritiesRelease) || (!!doc && !!doc.authoritiesRelease && !!doc.authoritiesRelease.codice)) &&
          this.isFieldDocumentNumberValid(docConfig, doc) &&
          (!this.isFieldMandatory(docConfig.documentType) || (!!doc && !!doc.documentType && !!doc.documentType.codice)) &&
          (!this.isFieldMandatory(docConfig.expirationDate) || (!!doc && !!doc.expirationDate)) &&
          (!this.isFieldMandatory(docConfig.locationsRelease) || (!!doc && !!doc.locationsRelease)) &&
          (!this.isFieldMandatory(docConfig.releaseDate) || (!!doc && !!doc.releaseDate)) && (!!!doc || doc && isValid)
      })
    } else {
      valid =
        this.getValidityByConf(docConfig)
    }
    return !docConfig || valid;
  }

  isFieldDocumentNumberValid(docConfig: any, doc: any) {
    if (this.isFieldMandatory(docConfig.documentNumber) && (doc && doc.documentType && doc.documentType.codice && doc.documentType.codice === '2')) {
      // only for driving license the document number is optional
      return true;
    } else {
      return (!this.isFieldMandatory(docConfig.documentNumber) || (!!doc && !!doc.documentNumber)) ;
    }
  }*/
}
