import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgPassproproCoreAjsModule} from '@rgi/ng-passpropro-core/portal';
import {NgPassproproSurveyAjsModule} from '@rgi/ng-passpropro-survey';

import {AJS_NG_PASSPROPRO_CONFIG} from '@rgi/ng-passpropro-core/portal';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgPassproproCoreAjsModule,
    NgPassproproSurveyAjsModule,
  ],
  providers:[
    {provide: AJS_NG_PASSPROPRO_CONFIG, useValue: {passportal: true}}
  ]
})
export class SurveyModule { }